import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Document } from 'src/app/models/document';
import { DataService } from 'src/app/services/data.service';
import { SiteService } from 'src/app/services/site.service';

@Component({
  selector: 'app-add-document',
  templateUrl: './add-document.component.html',
  styleUrls: ['./add-document.component.scss']
})
export class AddDocumentComponent {

	@Input() employerID: string;
	@Input() existingDocuments: Array<Document> = [];
	@Output() onClose: EventEmitter<any> = new EventEmitter();
	@Output() onSave: EventEmitter<any> = new EventEmitter();

	public activeTab: string = 'existing';
	public allDocuments: Array<Document> = [];
	public document: Document
	public documentsToAdd: Array<Document> = [];
	public form: FormGroup;

	constructor(private dataService: DataService, private siteService: SiteService, private fb: FormBuilder) { }

	ngOnInit() {
		this.dataService.getDocuments().subscribe((documents: any) => {
			this.allDocuments = documents.filter((d:any) => !this.existingDocuments.map(ed => ed.docID).includes(d.docID)).map((doc:any) => new Document(doc));
		});
		this.document = new Document({  docID: crypto.randomUUID(), employerID: this.employerID }); 
		this.initForm();
		this.form.valueChanges.subscribe(() => {
			this.document = new Document({ ...this.document, ...this.getNonNullProperties<Document>(this.form.value) });
		});
	}

	private initForm() {
		this.form = this.fb.group({
			docID: [this.document.docID],
			employerID: [this.document.employerID],
			docTitle: [this.document.docTitle, Validators.required],
			docAuthor: [this.document.docAuthor, Validators.required],
			docFile: ['', Validators.required],
			docUploadID: [this.document.docUploadID, Validators.required]
		});
	}
	setActiveTab(tab: string) {
		this.activeTab = tab;
	}

	addDocument(document: Document) {
		this.allDocuments.find(d => d.docID === document.docID).adding = true;
		this.documentsToAdd.push(document);
	}
	cancelAddDocument(document: Document) {
		this.allDocuments.find(d => d.docID === document.docID).adding = false;
		this.documentsToAdd = this.documentsToAdd.filter(d => d.docID !== document.docID);
	}
	saveNewDocuments() {
		this.dataService.saveEmployerDocuments(this.employerID, [ ...this.documentsToAdd, ...this.existingDocuments ]).subscribe((docs: any) => {
			this.siteService.setMessage('Documents added successfully', 'success');
			this.onSave.emit();
			this.close();
		});
	}

	createNewDocument() {
		this.form.markAllAsTouched();
		if(this.form.valid) {
		this.dataService.saveDocument(this.document).subscribe((d: any) => {
			this.documentsToAdd.push(new Document({ ...this.document }));
			this.saveNewDocuments();
		});
		} else {
			this.siteService.setMessage('Please fill in all required fields', 'error');
		}
	}

	close() {
		// Close the dialog
		this.onClose.emit();
	}

	onDocumentFileChange(e:any) {
		this.document.docFile = e.filePath;
		this.form.get('docUploadID').setValue(this.document.docUploadID);
		this.form.get('docFile').setValue(e.filePath);
	}

	onFileRemoved() {
		this.document.docFile = null;
		this.document.docUploadID = null;
	}
	isValid(field:string) {
		return !this.form.get(field).touched || this.form.get(field).valid;
	}
	getNonNullProperties<T>(obj: T): Partial<T> {
		const result: Partial<T> = {};
	  
		for (const key in obj) {
		  if (obj.hasOwnProperty(key) && obj[key as keyof T] !== null && obj[key as keyof T] !== undefined) {
			result[key as keyof T] = obj[key as keyof T];
		  }
		}
	  
		return result;
	}
}
