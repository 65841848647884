import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Employer } from "../models/employer";
import { of } from "rxjs";
import { ContentCard } from "../models/content-card";
import { User } from "../models/user";
import { Interaction } from "../models/interaction";
import { ReportData } from "../models/report-data";
import { DocTag, Document } from "../models/document";

@Injectable()
export class DataService {

	constructor(private apiService: ApiService) {}

	//#region USER
	getEmployerIdFromPath(path: string) {
		return this.apiService.get<string>(`employers/${path}/employerID`);
	}
	getEmployer(employerID: string) {
		return this.apiService.get<Employer>(`employers/${employerID}`);
	}
	
	//endregion

	//#region ADMIN

	saveEmployerConfig(employerID: string, employerName: string, path: string, logoFileUploadID: string) {
		return this.apiService.post<Employer>(`employers/${employerID}`, { employerName, path, logoFileUploadID });
	}

	uploadFile(uploadID: string, uploadFile: File) {
		const formData: FormData = new FormData();
		formData.append('uploadId', uploadID);
		formData.append(`uploadFile`, uploadFile, uploadFile.name);
		return this.apiService.postBlob(`file`, formData)
	}

	uploadCensusData(employerID: string, uploadFile: File) {
		const formData: FormData = new FormData();
		formData.append(`file`, uploadFile, uploadFile.name);
		return this.apiService.postBlob(`employers/${employerID}/censusdata`, formData)
	}
	getAllCards(type: number, employerID: string = '') {
		return this.apiService.get<Array<ContentCard>>(`cards/?cardContainerID=${type}${employerID ? `&employerID=${employerID}` : ''}`);
	}

	saveEmployerCards(employerID: string, cardContainerID: number, cards: Array<{cardID: string, sortOrder: number}>) {
		return this.apiService.post(`employers/${employerID}/cards/${cardContainerID}`, cards);
	}

	saveEmployerCard(card: ContentCard) {
		let { resetCard, ...data } = card;
		return this.apiService.post(`cards/${card.cardID}`, data);
	}
	saveEmployerContent(employerID: string, content: Array<{ contentID: string, textContent: string, fileUploadID: string }>) {
		return this.apiService.post(`employers/${employerID}/content`, content);
	}

	copyEmployer(employerID: string, newEmployerID: string, newEmployerName: string, newEmployerPath: string) {
		return this.apiService.post(`employers/${employerID}/copy/${newEmployerID}`, { newEmployerName, newEmployerPath });
	}
	deleteEmployer(employerID: string) {
		return this.apiService.delete(`employers/${employerID}`);
	}
	getUsers() {
		return this.apiService.get<Array<User>>(`users`);
	}
	getUser(userID: string) {
		return this.apiService.get<User>(`users/${userID}`);
	}
	saveUser(user: User) {
		return this.apiService.post(`users/${user.userID}`, user);
	}
	deleteUser(userID: string) {
		return this.apiService.delete(`users/${userID}`);
	}

	runReport(reportData: ReportData) {
		return this.apiService.post(`report`, reportData, null, 'arraybuffer');
	}

	deleteCard(cardID: string) {	
		return this.apiService.delete(`cards/${cardID}`);
	}

	getDocuments(employerID?: string) {
		if(employerID) {
			return this.apiService.get<Array<Document>>(`employers/${employerID}/docs`);
		} else {
			return this.apiService.get<Array<Document>>(`docs`);
		}
	}
	saveDocument(doc: Document) {
		return this.apiService.post(`docs/${doc.docID}`, doc);
	}
	saveEmployerDocuments(employerID: string, docs: Array<Document>) {
		return this.apiService.post(`employers/${employerID}/docs`, docs.map(d => d.docID));
	}
	deleteDocument(docID: string) {
		return this.apiService.delete(`docs/${docID}`);
	}
	getTags() {
		return this.apiService.get<Array<DocTag>>(`tags`);
	}
	saveTag(tag: { tagID: string, tagDesc: string }) {
		return this.apiService.post(`tags/${tag.tagID}`, tag.tagDesc);
	}
	deleteTag(tagID: string) {
		return this.apiService.delete(`tags/${tagID}`);
	}
	//endregion

	//#region Login
	login(email: string, password: string) {
		return this.apiService.post<string>(`login`, { email, password }, null, "text");
	}

	//endregion

	//#region Employers
	getEmployers() {
		return this.apiService.get<Array<Employer>>(`employers`);
	}

	logInteraction(interaction: Interaction) {
		return this.apiService.post(`engagement`, interaction);
	}

	generateEmployerDocs(employerID: string) {
		return this.apiService.post(`employers/${employerID}/docgen`, null);
	}

	//endregion

}