<div class="edit-document">
	<div class="content">
		<div class="form" [formGroup]="form">
			<div class="field">
				<label for="title">Title</label>
				<input pInputText type="text" id="title" [formControlName]="'docTitle'" />
			</div>
			<div class="field">
				<label for="author">Author</label>
				<input pInputText id="author" [formControlName]="'docAuthor'" />
			</div>
			<div class="field">
				<label for="content">File</label>
				<app-file-upload 
					[(id)]="document.docUploadID" 
					[allowedTypes]="null" 
					(fileUploaded)="onImageFileChange($event)"
					(fileRemoved)="onFileRemoved()"
					 [valid]="isValid('docUploadID')">
				</app-file-upload>
				<div class="filename" *ngIf="document.docFile">
					<span class="material-symbols-outlined">description</span>
					<span>Uploaded document:</span>
					<a [href]="document.docFile" target="_blank">{{document.docTitle}} ({{document.fileType}})</a>
				</div>
			</div>
		</div>
	</div>
	<div class="tags">
		<app-tags [(selectedTagIds)]="document.docTags" [allowEditing]="false"></app-tags>
	</div>
</div>

<div class="actions">
	<div class="button success" (click)="saveDocument()">Save</div>
	<div class="button neutral" (click)="close()">Close</div>			
</div>