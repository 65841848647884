import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-crisis-card',
  templateUrl: './crisis-card.component.html',
  styleUrls: ['./crisis-card.component.scss']
})
export class CrisisCardComponent {

	@Input() icon: string = '';
	@Input() head: string = '';
	@Input() body: string = '';
	@Input() link: string = '';
}
