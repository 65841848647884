

<div class="tabs">
	<div class="tab" *ngIf="activeTab == 'video-cards'">
		<div class="video-cards">
			<ng-container *ngFor="let card of videoCards">
				<app-admin-card [cardData]="card" [canEdit]="true" [canDelete]="true" (onAddRemoveCard)="onCardDeleted($event)" (onEditCard)="onCardEdited($event)"></app-admin-card>
			</ng-container>
		</div>
		
	</div>
	<div class="tab" *ngIf="activeTab == 'topic-cards'">
		<div class="topic-cards">
			<ng-container *ngFor="let card of topicCards">
				<app-admin-card [cardData]="card" [canEdit]="true" [canDelete]="true" (onAddRemoveCard)="onCardDeleted($event)" (onEditCard)="onCardEdited($event)"></app-admin-card>
			</ng-container>
		</div>
	</div>
</div>


<ng-container *ngIf="editingCard">
	<div class="mask" *ngIf="showEditCardModal">
		<div class="dialog">
			<h2>{{modalTitle}}</h2>
			<div class="dialog-content">
				<app-edit-card [cardData]="editingCard" (onClose)="closeEditCardDialog($event)"></app-edit-card>
			</div>
	
		</div>
	</div>
</ng-container>
	
	
<app-confirm-dialog
	[confirmHeader]="'Are you sure?'"
	[confirmBodyMsg]="'Are you sure you want to delete this card?'"
	[showDialog]="showDeleteCardConfirmation"
	[buttons]="[{ text: 'Cancel', class: 'neutral' }, { text: 'Delete', class: 'danger' }]"
	(onClick)="onConfirmClick($event)"></app-confirm-dialog>
