import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContentCard } from 'src/app/models/content-card';
import { DataService } from 'src/app/services/data.service';
import { SiteService } from 'src/app/services/site.service';

@Component({
  selector: 'app-edit-card',
  templateUrl: './edit-card.component.html',
  styleUrls: ['./edit-card.component.scss']
})
export class EditCardComponent {

	@Input() cardData: ContentCard;

	@Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

	public form: FormGroup;

	constructor(private dataService: DataService, private siteService: SiteService, private fb: FormBuilder) { }

	ngOnInit(): void {
		this.form = this.fb.group({
			tag: [this.cardData.tag, Validators.required],
			title: [this.cardData.title, Validators.required],
			cardContent: [this.cardData.cardContent, Validators.required],
			cardContentDetail: [this.cardData.cardContentDetail],
			imageFile: [this.cardData.imageFile],
			imageUploadID: [this.cardData.imageUploadID, Validators.required],
			videoFile: [this.cardData.videoFile],
			videoUploadID: [this.cardData.videoUploadID],
		});
		switch (this.cardData.cardContainerID) {
			case 1:
				this.form.get('videoUploadID').setValidators([Validators.required]);
				break;
			case 2:
				this.form.get('cardContentDetail').setValidators([Validators.required]);
				break;
		}
		this.form.valueChanges.subscribe(() => {
			this.cardData = new ContentCard({ ...this.cardData, ...this.getNonNullProperties<ContentCard>(this.form.value) })
		});


	}
	onImageFileChange({ filePath }: any, type: string) {
		if(type === 'image') {
			this.cardData.imageFile = filePath
			this.form.get('imageUploadID').setValue(this.cardData.imageUploadID);
		} else {
			this.cardData.videoFile = filePath;
			this.form.get('videoUploadID').setValue(this.cardData.videoUploadID);
		}
	}
	save(): void {
		this.form.markAllAsTouched();
		if(this.form.invalid) {
			this.siteService.setMessage('Please fill out all required fields', 'error', 5000);
		} else {
			this.cardData.tag = this.form.get('tag').value;
			this.cardData.title = this.form.get('title').value;
			this.cardData.cardContent = this.form.get('cardContent').value;
			this.cardData.cardContentDetail = this.form.get('cardContentDetail').value;
			this.cardData.imageUploadID = this.form.get('imageUploadID').value;
			this.cardData.videoFile = this.form.get('videoFile').value;
			this.cardData.videoUploadID = this.form.get('videoUploadID').value;

			this.dataService.saveEmployerCard(this.cardData).subscribe({
				next: () => {
					this.siteService.setMessage('Card saved');
					this.onClose.emit(true);
				},
				error: (err) => {
					this.siteService.setMessage('There was a problem saving your card: ' + err.error.errors[""][0], 'error', 7500);
				}
			});
		}

	}
	close(): void {
		this.onClose.emit(false);
	}

	isValid(field:string) {
		return !this.form.get(field).touched || this.form.get(field).valid;
	}

	getNonNullProperties<T>(obj: T): Partial<T> {
		const result: Partial<T> = {};
	  
		for (const key in obj) {
		  if (obj.hasOwnProperty(key) && obj[key as keyof T] !== null && obj[key as keyof T] !== undefined) {
			result[key as keyof T] = obj[key as keyof T];
		  }
		}
	  
		return result;
	  }
}

