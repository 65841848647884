import { Component, Input } from '@angular/core';
import * as ScrollMagic from 'scrollmagic';

@Component({
  selector: 'app-stressors',
  templateUrl: './stressors.component.html',
  styleUrls: ['./stressors.component.scss']
})
export class StressorsComponent {

	@Input() path: string;
	public stage = 0;
	ngAfterViewInit() {
		this.getStressorGridCellDimensions();
		window.addEventListener('resize', () => this.getStressorGridCellDimensions());

		let controller = new ScrollMagic.Controller();
		//let offset = document.getElementById('stressors').offsetTop;
		let offset = Math.floor(window.innerHeight * 0.5);
		let scene = new ScrollMagic.Scene({
			triggerElement: '#stressors',
			duration: 3000,
			offset: offset
		}).setPin('#stressors').on("progress", (e:any) => {
			this.stage = Math.max(Math.floor(e.progress / (1/6)), 0)
		}).addTo(controller);
	}

	getStressorGridCellDimensions() {
		// Select the grid container
		const gridContainer = document.querySelector('.stressor-grid');

		// Get the computed style of the grid container
		const gridStyles = window.getComputedStyle(gridContainer);

		// Extract grid template column values and gap size
		const columnSizes = gridStyles.gridTemplateColumns.split(' ');
		const rowGap = parseFloat(gridStyles.gap) || parseFloat(gridStyles.rowGap);
		const colGap = parseFloat(gridStyles.gap) || parseFloat(gridStyles.columnGap);

		// Calculate the width of the first grid cell
		//seems like we don't need to consider gap size here
		//const cellWidth = gridContainer.clientWidth / columnSizes.length - gapSize * (columnSizes.length - 1) / columnSizes.length;
		const cellWidth = parseFloat(columnSizes[0]);
		// Extract grid template row values
		const rowSizes = gridStyles.gridTemplateRows.split(' ');

		// Calculate the height of the first grid cell
		const cellHeight = parseFloat(rowSizes[0]);

		document.documentElement.style.setProperty('--cell-width', `${cellWidth}px`);
		document.documentElement.style.setProperty('--cell-height', `${cellHeight}px`);
		document.documentElement.style.setProperty('--row-gap', `${rowGap}px`);
		document.documentElement.style.setProperty('--col-gap', `${colGap}px`);

	}

}
