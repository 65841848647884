
<div class="topic-card" (click)="cardData.isOpen = !cardData.isOpen" [ngClass]="{open: cardData.isOpen, added: cardData.added}" [interact]="{engagementTypeID: 2, resource: cardData.title}">
	<div class="front">
		<div class="image" [ngStyle]="{'background-image': 'url(' + cardData.imageFileQuoted + ')'}"><div class="topic-name" *ngIf="cardData.tag">{{cardData.tag}}</div></div>
		<div class="copy">
			<h3>{{cardData.title}}</h3>
			<div class="expander"></div>
			<p class="content-short" [innerHTML]="fixLineBreaks(cardData.cardContent)"></p>
		</div>
	</div>
	<div class="overlay">
		<div class="content">
			<h3>{{cardData.title}}</h3>
			<div class="collapser"></div>
			<p class="content-long" [innerHTML]="fixLineBreaks(cardData.cardContentDetail)"></p>
		</div>
	</div>
</div>
