import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserTemplateComponent } from './components/user-template/user-template.component';
import { HomeComponent } from './components/home/home.component';
import { LearnMoreComponent } from './components/learn-more/learn-more.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { CrisisCardComponent } from './components/crisis-card/crisis-card.component';
import { FaqComponent } from './components/faq/faq.component';
import { StressorsComponent } from './components/stressors/stressors.component';
import { PhonePipe } from 'src/app/pipes/phone.pipe';
import { CarouselModule } from 'primeng/carousel';
import { LayoutModule } from '@angular/cdk/layout';
import { SafePipe } from '../../pipes/safe.pipe';

const routes: Routes = [
	{ path: "", component: UserTemplateComponent, children: [
		//{ path: "learn-more/:path", component: LearnMoreComponent, data: { activeLink: 'learn-more' } },
		{ path: "learn-more", component: LearnMoreComponent, data: { activeLink: 'learn-more' } },
		{ path: "video/:vimeoId", component: HomeComponent, data: { activeLink: 'home' } },
		{ path: "", component: HomeComponent, data: { activeLink: 'home' } },
    { path: "home", component: HomeComponent, data: { activeLink: 'home' } },
	]}
];

@NgModule({
  declarations: [
    UserTemplateComponent,
    HomeComponent,
    LearnMoreComponent,
    CrisisCardComponent,
    FaqComponent,
    StressorsComponent,

	PhonePipe,
    SafePipe
  ],
  imports: [
    CommonModule,
	RouterModule.forChild(routes),

	SharedModule,

	CarouselModule,
	LayoutModule,
  ],
})
export class UserModule { }
