import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { Employer } from 'src/app/models/employer';
import { HeaderButton } from 'src/app/models/header-button';
import { AdminService } from 'src/app/services/admin.service';
import { SessionService } from 'src/app/services/session.service';
import { SiteService } from 'src/app/services/site.service';

@Component({
  selector: 'app-admin-template',
  templateUrl: './admin-template.component.html',
  styleUrls: ['./admin-template.component.scss']
})
export class AdminTemplateComponent implements OnInit, OnDestroy {

	public activeLink: string = "users";
	public activeSection: string = '';
	public pageTitle: string = '';
	public headerButtons: Array<HeaderButton> = [];
	public backButton: { title?: string, link: string } = null;

	public employer: Employer = null;
	public isSuperAdmin: boolean = false;
	public superAdminEmployerID: number = null;

	private destroy$: EventEmitter<boolean> = new EventEmitter();

	constructor(private route: ActivatedRoute, private router: Router, private siteService: SiteService, private adminService: AdminService, private sessionService: SessionService) {}

	ngOnInit(): void {
		this.isSuperAdmin = this.adminService.isSuperAdmin;
		this.superAdminEmployerID = this.adminService.employerId

		this.route.firstChild.data.subscribe(d => {
			this.activeLink = d?.activeLink;
		})
		this.activeSection = this.route.snapshot.firstChild?.paramMap.get('tab') || this.route.snapshot.firstChild.data['activeSection'];

		this.router.events.subscribe(e => {
			if(e.type == 11) {
				this.activeLink = e.snapshot.firstChild.data?.activeLink;
				this.activeSection = e.snapshot.firstChild?.paramMap.get('tab') || e.snapshot.firstChild.data['activeSection'];
				window.scrollTo(0, 0);
				this.siteService.headerButtons = [];
				this.siteService.backButton = null;
			}
		});

		this.siteService.onPageTitleSet.subscribe((t) => setTimeout(() => {
			this.pageTitle = t;
		}, 0));

		this.siteService.onHeaderButtonsSet.pipe(takeUntil(this.destroy$)).subscribe((b) => {
			this.headerButtons = b;
		});
		this.siteService.onBackButtonSet.pipe(takeUntil(this.destroy$)).subscribe((b) => {
			this.backButton = b;
		});

		this.adminService.onEmployerSet.subscribe((e:Employer) => {
			this.employer = e;
		});

;
	}

	do(callback:Function) {
		callback();
	}
	logout() {
		this.sessionService.logout();
		this.router.navigate(['admin', 'login']);
	}

	ngOnDestroy(): void {
		this.destroy$.emit(true);
		this.destroy$.complete();
	}
}
