import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ContentCard } from 'src/app/models/content-card';

@Component({
  selector: 'app-admin-card',
  templateUrl: './admin-card.component.html',
  styleUrls: ['./admin-card.component.scss']
})
export class AdminCardComponent {
	@Input() canEdit: boolean = false;
	@Input() canDelete: boolean = false;
	@Input() canAdd: boolean = false;
	
	@Input() cardData: ContentCard
	@Output() onAddRemoveCard: EventEmitter<{include: boolean, cardID: string}> = new EventEmitter<{include: boolean, cardID: string}>();
	@Output() onEditCard: EventEmitter<ContentCard> = new EventEmitter<ContentCard>();

	public adding: boolean = false;
	public deleting: boolean = false;

	ngOnInit(): void {
		this.cardData.resetCard.asObservable().subscribe(() => {
			this.adding = false;
			this.deleting = false;
		});
	}

	addCard(): void {
		this.adding = true;
		this.onAddRemoveCard.emit({include: true, cardID: this.cardData.cardID});
	}	
	removeCard(): void {
		this.adding = false;
		this.onAddRemoveCard.emit({include: false, cardID: this.cardData.cardID});
	}
	deleteCard(): void {
		this.deleting = true;
		this.onAddRemoveCard.emit({include: false, cardID: this.cardData.cardID});
	}
	editCard(): void {
		this.onEditCard.emit(this.cardData);
	}	
}
