
<div class="field">
	<label>Search</label>
	<input pInputText type="text" [(ngModel)]="searchTerm" (keyup)="search()" />
</div>
<div class="employers">
	<div class="header name">Employer Name</div>
	<div class="header actions">&nbsp;</div>
	<ng-container *ngFor="let employer of employers">
		<div class="name"><a [routerLink]="['/admin', 'employer', employer.employerID]">{{employer.employerName}}</a></div>
		<div>
			<div class="actions">
				<div class="button neutral" (click)="copyLink(employer)">Copy Link</div>
				<div class="button neutral" (click)="preview(employer)">Preview</div>
				<div class="button success" (click)="copy(employer)">Copy Employer</div>
			</div>
		</div>
	</ng-container>
</div>

	<div class="mask" *ngIf="showCopyEmployerDialog">
		<div class="dialog">
			<h2>Copy Employer</h2>
			<div class="dialog-content">
				<div class="field">
					<label for="employerName">New Employer Name</label>
					<input pInputText type="text" id="employerName" [(ngModel)]="newEmployerName" />
				</div>
				<div class="field">
					<label for="employerPath">New Employer Path</label>
					<input pInputText type="text" id="employerPath" [(ngModel)]="newEmployerPath" />
				</div>
			</div>
			<div class="actions">
				<div class="button neutral" (click)="closeCopyEmployerDialog()">Cancel</div>
				<div class="button success" (click)="doCopy()">Save</div>
			</div>
		</div>
	</div>
	
	