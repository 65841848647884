import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import { DataService } from 'src/app/services/data.service';
import { SiteService } from 'src/app/services/site.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
	public username: string;
	public password: string;

	private returnUrl: string

	constructor(private dataService: DataService, private route: ActivatedRoute, private router: Router, private adminService: AdminService, private siteService: SiteService) {}

	ngOnInit(): void {
		this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/admin';
	}
	login() {
		this.dataService.login(this.username, this.password).subscribe(token => {
			this.adminService.token = token;
			if(this.adminService.isSuperAdmin) {
				this.router.navigateByUrl(this.returnUrl);
			} else {
				this.router.navigate(['admin', 'employer', this.adminService.employerId, 'census-data']);
			}
		}, (err) => {
			if(err.status !== 500) {
				this.siteService.setMessage("The username/password combination was not correct.", "error")
			}
		})
	}
}
