
<div class="video-card" (click)="playVideo()">
	<div class="thumbnail" [ngStyle]="{'background-image': 'url(' + cardData.imageFileQuoted + ')'}">
		<div class="tag">{{cardData.tag}}</div>
		<div class="play-button"><img src="../../../../../assets/images/play-button.png"></div>
	</div>
	<div class="copy">
		<h3>{{cardData.title}}</h3>
		<p [innerHTML]="cardData.cardContent"></p>
	</div>
</div>
