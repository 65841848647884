import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Document } from 'src/app/models/document';


@Component({
  selector: 'app-library-item',
  templateUrl: './library-item.component.html',
  styleUrls: ['./library-item.component.scss']
})
export class LibraryItemComponent {

	@Input() document: Document;
	@Input() isSuperAdmin: boolean = false;
	@Input() buttons: Array<'edit' | 'delete' | 'add' | 'cancel' | 'remove' | ''> = ['edit', 'delete'];
	@Output() onEditClick: EventEmitter<Document> = new EventEmitter();
	@Output() onDeleteClick: EventEmitter<Document> = new EventEmitter();
	@Output() onAddClick: EventEmitter<Document> = new EventEmitter();
	@Output() onCancelClick: EventEmitter<Document> = new EventEmitter();
	@Output() onRemoveClick: EventEmitter<Document> = new EventEmitter();



	constructor() { }

	editDocument() {
		this.onEditClick.emit(this.document);
	}

	deleteDocument() {
		this.onDeleteClick.emit(this.document);
	}

	addDocument() {
		this.onAddClick.emit(this.document);
	}

	cancelAddDocument() {
		this.onCancelClick.emit(this.document);
	}
	removeDocument() {
		this.onRemoveClick.emit(this.document);
	}


}
