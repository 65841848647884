import { ContentCategory } from "./content";
import { ContentCard, ContentCardContainer } from "./content-card";

export class Employer {
	public employerID: string;
	public employerName: string;
	public path: string;
	public employerLogoUploadID: string;
	public qrCodeFileUploadID: string;
	public logo: string;
	public qrCode: string;
	public employerContent: EmployerContent;
	public employerCards: EmployerCards;

	constructor(o?: any) {
		if(o.employerContent) {
			o.employerContent = new EmployerContent(o.employerContent);
		}
		Object.assign(this, o);
	}
}

export class EmployerContent {
	public contentCategories: Array<ContentCategory>;

	constructor(o?: any) {
		if(o.contentCategories) {
			o.contentCategories = o.contentCategories.map((c:any) => new ContentCategory(c));
		}
		Object.assign(this, o);
	}

	public isMatch(compareTo: EmployerContent): boolean {
		if(this.contentCategories.length !== compareTo.contentCategories.length) {
			return false;
		}
		for(let i = 0; i < this.contentCategories.length; i++) {
			if(!this.contentCategories[i].isMatch(compareTo.contentCategories[i])) {
				return false;
			}
		}
		return true;
	}
}

export class EmployerCards {
	public cardContainers: Array<ContentCardContainer>;

	constructor(o?: any) {
		Object.assign(this, o);
	}
}

