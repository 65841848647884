import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IPendingChangesComponent } from 'src/app/guards/pending-changes.guard';
import { Employer } from 'src/app/models/employer';
import { User } from 'src/app/models/user';
import { DataService } from 'src/app/services/data.service';
import { SiteService } from 'src/app/services/site.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements IPendingChangesComponent {

	public user: User;
	public employers: Array<Employer> = [];
	public errors: any = {};
	public showDeleteUserConfirmation: boolean = false;
	public selectedEmployer: Employer;
	public isDirty: boolean = false;

	constructor(private dataService: DataService, private siteService: SiteService, private route: ActivatedRoute, private router: Router) {
		let apiCalls:Array<Observable<any>> = [
			this.dataService.getEmployers().pipe(tap(e => {
				this.employers = e.map((e) => new Employer(e));
				this.employers.unshift(new Employer({ employerID: null, employerName: 'Super Admin' }));
			}))
		];
		this.route.paramMap.subscribe(p => {
			let id = p.get('id');
			if(id === 'new') {
				this.user = new User({ userID: crypto.randomUUID() });
				this.siteService.pageTitle = 'New User';
			} else {
				apiCalls.push(this.dataService.getUser(id).pipe(tap((u) => {
					this.user = new User(u);
					this.siteService.pageTitle = `${this.user.firstName} ${this.user.lastName}`;
				})));
			}
			this.dataService.getEmployers().subscribe((e) => {
				this.employers = e.map((e) => new Employer(e));
				this.employers.unshift(new Employer({ employerID: null, employerName: 'Super Admin' }));
			});
			forkJoin(apiCalls).subscribe(() => {
				this.selectedEmployer = this.employers.find((e) => e.employerID === this.user.employerID);
				setTimeout(() => {
					this.isDirty = false;
				}, 100)
			});
			this.siteService.headerButtons = [
				{ text: 'Save', class: 'success', callback: () => this.save() },
				{ text: 'Delete', class: 'danger', callback: () => this.delete() }
			];
			this.siteService.backButton = { title: 'Back to Users', link: '/admin/users' };

		});
		
	}

	markDirty() {
		this.isDirty = true;
	}
	canDeactivate(): boolean | Observable<boolean> {
		return !this.isDirty;
	}
	save() {
		return new Promise<boolean>((resolve, reject) => {
			this.errors = {};
			this.user.employerID = this.selectedEmployer.employerID;
			this.dataService.saveUser(this.user).subscribe(() => {
				this.siteService.setMessage('User saved');
				this.isDirty = false;
				resolve(true);
			}, (e) => {
				this.errors = e.error.errors;
				reject(false);
			});
		});
	}
	delete() {
		this.showDeleteUserConfirmation = true;
	}

	doDeleteUser() {
		this.dataService.deleteUser(this.user.userID).subscribe(() => {
			this.siteService.setMessage('User deleted');
			this.router.navigate(['/admin/users']);
		});
	}
	onConfirmClick({ action }: {action: string, data: any}) {
		if(action === 'Delete') {
			this.doDeleteUser();
		} else {
			this.showDeleteUserConfirmation = false;
		}
	}
}
