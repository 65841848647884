import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminTemplateComponent } from './components/admin-template/admin-template.component';
import { LoginComponent } from './components/login/login.component';
import { RouterModule, Routes } from '@angular/router';
import { AdminRootComponent } from './components/admin-root/admin-root.component';

import { PasswordModule } from 'primeng/password';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';
import { FileUploadModule } from 'primeng/fileupload';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DropdownModule } from 'primeng/dropdown';
import { EmployersComponent } from './components/employers/employers.component';
import { GlobalCardsComponent } from './components/global-cards/global-cards.component';
import { UsersComponent } from './components/users/users.component';
import { ReportsComponent } from './components/reports/reports.component';
import { SharedModule } from '../shared/shared.module';
import { EmployerComponent } from './components/employer/employer.component';
import { AddCardComponent } from './components/add-card/add-card.component';
import { AdminCardComponent } from './components/admin-card/admin-card.component';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { CarouselModule } from 'primeng/carousel';
import { OrderListModule } from 'primeng/orderlist';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { EditCardComponent } from './components/edit-card/edit-card.component';
import { UserComponent } from './components/user/user.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { isAdminGuard, isSuperAdminGuard } from 'src/app/guards/is-admin.guard';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { PendingChangesGuard } from 'src/app/guards/pending-changes.guard';
import { LibraryComponent } from './components/library/library.component';
import { LibraryItemComponent } from './components/library/library-item/library-item.component';
import { AddDocumentComponent } from './components/add-document/add-document.component';
import { EditDocumentComponent } from './components/edit-document/edit-document.component';
import { TagsComponent } from './components/tags/tags.component';

const AdminRoutes: Routes = [
	{ path: "", component: AdminRootComponent, children: [
		{ path: "login", component: LoginComponent },
		{ path: "", component: AdminTemplateComponent, children: [
			{ path: "", pathMatch: "full", component: EmployersComponent, canActivate: [ isSuperAdminGuard ], data: { activeLink: 'employers', activeSection: 'configure' } },
			{ path: "employers", component: EmployersComponent, canActivate: [ isSuperAdminGuard ], data: { activeLink: 'employers' } },
			{ path: "employer/:id/census-data", component: EmployerComponent, canActivate: [ isAdminGuard ], data: { activeLink: 'employer', activeSection: 'census-data'  } },
			{ path: "employer/:id/library", component: EmployerComponent, canActivate: [ isAdminGuard ], data: { activeLink: 'employer', activeSection: 'library'  } },
			{ path: "employer/:id/:tab", component: EmployerComponent, canActivate: [ isSuperAdminGuard ], canDeactivate: [ PendingChangesGuard ], data: { activeLink: 'employer' } },
			{ path: "employer/:id", component: EmployerComponent, canActivate: [ isSuperAdminGuard ], canDeactivate: [ PendingChangesGuard ], data: { activeLink: 'employer', activeSection: 'configure' } },
			{ path: "global-cards/:tab", component: GlobalCardsComponent, canActivate: [ isSuperAdminGuard ], data: { activeLink: 'global-cards' } },
			{ path: "global-cards", component: GlobalCardsComponent, canActivate: [ isSuperAdminGuard ], data: { activeLink: 'global-cards', activeSection: 'video-cards' } },
			{ path: "library", component: LibraryComponent, canActivate: [ isAdminGuard ], data: { activeLink: 'library' } },
			{ path: "users", component: UsersComponent, canActivate: [ isSuperAdminGuard ], data: { activeLink: 'users' } },
			{ path: "user/:id", component: UserComponent, canActivate: [ isSuperAdminGuard ], canDeactivate: [ PendingChangesGuard ], data: { activeLink: 'users' } },
			{ path: "reports", component: ReportsComponent, canActivate: [ isSuperAdminGuard ], data: { activeLink: 'reports' } },
		]}
]},

];

@NgModule({
  declarations: [
    AdminTemplateComponent,
    LoginComponent,
    AdminRootComponent,
    EmployersComponent,
    GlobalCardsComponent,
    UsersComponent,
    ReportsComponent,
    EmployerComponent,
    AddCardComponent,
    AdminCardComponent,
    EditCardComponent,
	UserComponent,
 ConfirmDialogComponent,
 FileUploadComponent,
 LibraryComponent,
 LibraryItemComponent,
 AddDocumentComponent,
 EditDocumentComponent,
 TagsComponent,
  ],
  imports: [
	FormsModule,
	ReactiveFormsModule,
	RouterModule.forChild(AdminRoutes),
    CommonModule,

	SharedModule,

	InputTextModule,
	InputTextareaModule,
	PasswordModule,
	DropdownModule,
	ButtonModule,
	ToastModule,
	FileUploadModule,
	DialogModule,
	DynamicDialogModule,
	CarouselModule,
	OrderListModule,
	CalendarModule,
	CheckboxModule,
	InputSwitchModule,
	ConfirmDialogModule,

  ]
})
export class AdminModule { }

