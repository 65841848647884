
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { tap, finalize } from 'rxjs/operators';
import { SiteService } from '../services/site.service';
import { SessionService } from '../services/session.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
	private noLoaderPages = ['/chat/'];

	constructor(private router:Router, private siteService: SiteService, private sessionService: SessionService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const authReq = request.clone();

		//let showLoader = (!!authReq.headers)?!authReq.headers.keys().includes('Noloader'):false;
		let showLoader = !this.noLoaderPages.some(pg => authReq.url.includes(pg))
		const guid = crypto.randomUUID();
		if(showLoader) {
			this.siteService.showLoader(guid);
		}
		return next.handle(authReq).pipe(
			tap((event: HttpEvent<any>) => {
				if (event instanceof HttpResponse) {
					// do stuff with response if you want
					if(showLoader) {
						this.siteService.hideLoader(guid);
					}
				}
			}, (err: any) => {
				if (err instanceof HttpErrorResponse) {
					if (err.status === 403) {
						this.sessionService.logout();
						this.router.navigate(['/admin', 'login'], { queryParams: { returnUrl: this.router.url } });
					}
				}
			}
		),
		finalize(() => {
			if(showLoader) {
				this.siteService.hideLoader(guid);
			}
		}));
	}

}
