<div class="card-holder" [ngClass]="{ adding: adding, deleting: deleting}">
	<div class="controls">
		<div><div class="button neutral" *ngIf="canEdit" (click)="editCard()">Edit</div></div>
		<div>
			<div class="button neutral" *ngIf="canAdd && !adding" (click)="addCard()">Add</div>
			<div class="button danger" *ngIf="canAdd && adding" (click)="removeCard()">Remove</div>
		</div>
		<div><div class="button danger" *ngIf="canDelete" (click)="deleteCard()">Delete</div></div>
	</div>
	<div class="card">
		<app-topic-card [cardData]="cardData" *ngIf="cardData.cardContainerID == 2"></app-topic-card>
		<app-video-card [cardData]="cardData" *ngIf="cardData.cardContainerID == 1"></app-video-card>
	</div>
</div>
