import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { HeaderButton } from 'src/app/models/header-button';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
	@Input() confirmHeader = '';
	@Input() confirmBodyMsg = '';
	@Input() showDialog = false;
	@Input() customFooter: TemplateRef<any>;
	@Input() customBodyTemp: TemplateRef<any>;
	@Input() buttons: Array<HeaderButton> = [];
	@Input() data: any; //whatever data you want to pass back
	@Output() onClick: EventEmitter<{action: string, data: any }> = new EventEmitter<{action: string, data: any }>();

	onButtonClick(btn: string) {
		this.onClick.emit({ action: btn, data: this.data });
	}
}
