import { Injectable } from "@angular/core";
import { Router } from "@angular/router";


@Injectable({
	providedIn: 'root'
})
export class SessionService {
	
	private _employerID: string;
	constructor(private router: Router) {}

	logout() {
		this.router.navigate(['/', 'login']);
	}
	get sessionID() {
		let sessionId = localStorage.getItem('sessionID');
		if(!sessionId) {
			sessionId = crypto.randomUUID();
			localStorage.setItem('sessionID', sessionId);
		}
		return sessionId;
	}

	get employerID() {
		return this._employerID;
	}
	set employerID(employerID: string) {
		this._employerID = employerID;
	}
}