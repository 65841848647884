<div class="upload-container" [ngClass]="{invalid: !valid}">
	<p-fileUpload [accept]="allowedTypes" (onSelect)="uploadImage($event)" [showUploadButton]="false" [showCancelButton]="false" #fileUpload [ngClass]="{'no-preview': !showPreview}">
		<ng-template pTemplate="file" let-file let-index="index">
			<div class="preview" *ngIf="showPreview">
				<a [href]="fileUrl" target="_blank">
					<img [src]="file.objectURL" />
				</a>
				<span>{{file.name}}</span>
				<div><div class="button neutral" (click)="removeFile()"><span class="material-symbols-outlined">clear</span></div></div>
			</div>
		</ng-template>
	</p-fileUpload>
	<div class="thumbnail" *ngIf="thumbnail && fileUpload.files.length == 0">
		<a [href]="fileUrl" target="_blank" *ngIf="fileUrl">
			<img [src]="thumbnail" />
		</a>
		<img [src]="thumbnail" *ngIf="!fileUrl" />
		<div class="button neutral">
			<span class="material-symbols-outlined" (click)="removeFile()">clear</span>
		</div>
	</div>	
</div>

