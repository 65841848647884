import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CarouselPageEvent } from 'primeng/carousel';
import { ContentCard } from 'src/app/models/content-card';
import { DataService } from 'src/app/services/data.service';
import { SiteService } from 'src/app/services/site.service';

@Component({
  selector: 'app-add-card',
  templateUrl: './add-card.component.html',
  styleUrls: ['./add-card.component.scss']
})
export class AddCardComponent {

	public activeTab: 'existing' | 'new' = 'existing';
	@Input() cardContainerID: number;
	@Input() employerID: string;
	@Input() existingCards: Array<string> = [];

	public allCards: Array<ContentCard> = [];
	public cardsToShow: Array<ContentCard> = [];
	private page: number = 1;
	public PAGE_SIZE: number = 3;
	private cardsToAdd: Array<string> = [];
	public carouselIndex: number = 0;
	public form: FormGroup;


	public newCard: ContentCard;
	@Output() onClose: EventEmitter<void> = new EventEmitter<void>();

	constructor(private dataService: DataService, private siteService: SiteService, private fb: FormBuilder) { }

	ngOnInit(): void {
		this.newCard = new ContentCard({ cardContainerID: this.cardContainerID, employerID: this.employerID, cardID: crypto.randomUUID() });
		this.dataService.getAllCards(this.cardContainerID, this.employerID).subscribe(cards => {
			this.allCards = cards.map(c => new ContentCard(c)).filter(c => !this.existingCards.includes(c.cardID));
			this.cardsToShow = this.allCards.slice((this.page - 1) * this.PAGE_SIZE, this.page * this.PAGE_SIZE);
		});
		this.form = this.fb.group({
			tag: [this.newCard.tag, Validators.required],
			title: [this.newCard.title, Validators.required],
			cardContent: [this.newCard.cardContent, Validators.required],
			cardContentDetail: [this.newCard.cardContentDetail],
			imageFile: [this.newCard.imageFile],
			imageUploadID: [this.newCard.imageUploadID, Validators.required],
			videoFile: [this.newCard.videoFile],
			videoUploadID: [this.newCard.videoUploadID],
		});
		switch (this.newCard.cardContainerID) {
			case 1:
				this.form.get('videoUploadID').setValidators([Validators.required]);
				break;
			case 2:
				this.form.get('cardContentDetail').setValidators([Validators.required]);
				break;
		}
		this.form.valueChanges.subscribe(() => {
			this.newCard = new ContentCard({ ...this.newCard, ...this.getNonNullProperties<ContentCard>(this.form.value) })
		});
	}

	addCard({include, cardID}: {include: boolean, cardID: string}): void {
		if (include) {
			this.cardsToAdd.push(cardID);
		} else {
			this.cardsToAdd = this.cardsToAdd.filter(c => c != cardID);
		}
	}
	setActiveTab(tab: 'existing' | 'new'): void {
		this.activeTab = tab;
	}
	saveNewCards(): void {
		let cards = this.existingCards.map((cardID,i) =>({ cardID, sortOrder: i })).concat(this.cardsToAdd.map((cardID, i) => ({ cardID, sortOrder: i + this.existingCards.length })));
		this.dataService.saveEmployerCards(this.employerID, this.cardContainerID, cards).subscribe( { 
			next: () => this.onClose.emit(),
			error: (err) => {
				this.siteService.setMessage('There was a problem saving your card: ' + err.error.errors[""][0], 'error', 7500);
			}
		});
	};
	saveNewCard(): void {
		this.form.markAllAsTouched();
		if(this.form.invalid) {
			this.siteService.setMessage('Please fill out all required fields', 'error', 5000);
		} else {
			this.newCard.tag = this.form.get('tag').value;
			this.newCard.title = this.form.get('title').value;
			this.newCard.cardContent = this.form.get('cardContent').value;
			this.newCard.cardContentDetail = this.form.get('cardContentDetail').value;
			this.newCard.imageUploadID = this.form.get('imageUploadID').value;
			this.newCard.videoFile = this.form.get('videoFile').value;
			this.newCard.videoUploadID = this.form.get('videoUploadID').value;
		
			this.dataService.saveEmployerCard(this.newCard).subscribe({ 
				next: () => {
					this.allCards.unshift(this.newCard);
					this.activeTab = 'existing';
				},
				error: (err) => {
					this.siteService.setMessage('There was a problem saving your card: ' + err.error.errors[""][0], 'error', 7500);
				}
			});
		}
	}
	isValid(field:string) {
		return !this.form.get(field).touched || this.form.get(field).valid;
	}
	close(): void {
		this.onClose.emit();
	}
	onPageChange(e: CarouselPageEvent) {
		this.carouselIndex = e.page;
	}
	onImageFileChange({ filePath }: any, type: string) {
		if(type === 'image') {
			this.newCard.imageFile = filePath
			this.form.get('imageFile').setValue(this.newCard.imageFile);
			this.form.get('imageUploadID').setValue(this.newCard.imageUploadID);
		} else {
			this.newCard.videoFile = filePath;
			this.form.get('videoFile').setValue(this.newCard.videoFile);
			this.form.get('videoUploadID').setValue(this.newCard.videoUploadID);
		}
	}

	getNonNullProperties<T>(obj: T): Partial<T> {
		const result: Partial<T> = {};
	  
		for (const key in obj) {
		  if (obj.hasOwnProperty(key) && obj[key as keyof T] !== null && obj[key as keyof T] !== undefined) {
			result[key as keyof T] = obj[key as keyof T];
		  }
		}
	  
		return result;
	  }
}
