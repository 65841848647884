import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RootComponent } from './components/root/root.component';
import { AdminTemplateComponent } from './modules/admin/components/admin-template/admin-template.component';
import { LandingComponent } from './modules/admin/components/landing/landing.component';



const routes: Routes = [
	{
		matcher: (url) => {
			const hostname = window.location.hostname;
			if (hostname.startsWith('admin.') && !url.some(segment => segment.path.startsWith('admin'))) {
				return {
					consumed: [],
					posParams: {}
				};
			}
			return null;
		},
		redirectTo: '/admin/login'
	},
	{ path: "", component: RootComponent, children: [
		{ path: "admin", loadChildren: () => import('src/app/modules/admin/admin.module').then(m => m.AdminModule) },
		{ path: "", loadChildren: () => import('src/app/modules/user/user.module').then(m => m.UserModule) }
	]}

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { bindToComponentInputs: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
