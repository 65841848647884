<section>
	<div class="field">
		<label>Report Name</label>
		<p-dropdown [options]="reports" [(ngModel)]="selectedReport" [optionLabel]="'name'" (onChange)="selectReport($event.value)" [autoDisplayFirst]="false"></p-dropdown>
	</div>
</section>

<section class="parameters" *ngIf="selectedReport">
	<div *ngFor="let param of selectedReport.parameters" [formGroup]="form">
		<ng-container [ngSwitch]="param.type">
			<div class="field" *ngSwitchCase="'date'">
				<label>{{param.name}}</label>
				<p-calendar [formControlName]="param.key" (onSelect)="setDate($event, param.key)" [appendTo]="'body'"></p-calendar>
			</div>
			<div class="field" *ngSwitchCase="'select'">
				<label>{{param.name}}</label>
				<p-dropdown *ngIf="param.options" [formControlName]="param.key" [options]="param.options" [optionLabel]="'label'" [autoDisplayFirst]="false" [appendTo]="'body'"></p-dropdown>
			</div>
			<div class="field" *ngSwitchDefault>
				<label>{{param.name}}</label>
				<input pInputText [formControlName]="param.key">
			</div>
		</ng-container>
	</div>
</section>
