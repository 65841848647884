<div class="edit-card">
	<div class="card-data" [formGroup]="form">
		<div class="field">
			<label for="newCardTag">Tag</label>
			<input pInputText type="text" id="newCardTag" [formControlName]="'tag'">
		</div>
		<div class="field">
			<label for="newCardTitle">Title</label>
			<input pInputText type="text" id="newCardTitle" [formControlName]="'title'">
		</div>
		<div class="field">
			<label for="newCardContent">Content</label>
			<textarea pInputTextarea id="newCardContent" [formControlName]="'cardContent'"></textarea>
		</div>
		<div class="field" *ngIf="cardData.cardContainerID == 2">
			<label for="newCardContentLong">Content</label>
			<textarea pInputTextarea id="newCardContentLong" [formControlName]="'cardContentDetail'"></textarea>
		</div>
		<div class="field">
			<label for="newCardImage">Image</label>
			<app-file-upload [(id)]="cardData.imageUploadID" [valid]="isValid('imageUploadID')" [thumbnail]="cardData.imageFile" (fileUploaded)="onImageFileChange($event, 'image')" [fileUrl]="cardData.imageFile"></app-file-upload>
		</div>
		<div class="field" *ngIf="cardData.cardContainerID == 1">
			<label for="newCardVideo">Video</label>
			<app-file-upload [(id)]="cardData.videoUploadID" [valid]="isValid('videoUploadID')" [thumbnail]="cardData.videoFile" [allowedTypes]="'video/*'" (fileUploaded)="onImageFileChange($event, 'video')"[fileUrl]="cardData.videoFile"></app-file-upload>
		</div>
	</div>

	<div class="preview">
		<app-video-card [cardData]="cardData" *ngIf="cardData.cardContainerID == 1"></app-video-card>
		<app-topic-card [cardData]="cardData" *ngIf="cardData.cardContainerID == 2"></app-topic-card>
	</div>

</div>
<div class="actions">
	<div class="button success" (click)="save()">Save</div>
	<div class="button neutral" (click)="close()">Close</div>			
</div>
