import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopicCardComponent } from './components/topic-card/topic-card.component';
import { VideoCardComponent } from './components/video-card/video-card.component';
import { InteractDirective } from './directives/interact.directive';



@NgModule({
  declarations: [
	TopicCardComponent,
	VideoCardComponent,
	InteractDirective,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
	TopicCardComponent,
	VideoCardComponent,
	InteractDirective,
  ]
})
export class SharedModule { }
