import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelpersService {
  
  constructor() { }

  /**
   * Generates the full employer URL based on the employer path
   * @param employerPath The subdomain path for the employer
   * @returns The complete URL for accessing the employer site
   */
  getEmployerUrl(employerPath: string): string {
    const urlParts = window.location.origin.split('://');
    const protocol = urlParts[0];
    const host = window.location.host;

    // Special handling for localhost
    if (host.includes('localhost')) {
      return `${protocol}://${employerPath}.localhost:${window.location.port}`;
    }

    const baseDomain = host.split('.').slice(-2).join('.');
    return `${protocol}://${employerPath}.${baseDomain}`;
  }
} 