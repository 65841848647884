import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subject, SubscriptionLike, timer } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { SiteService } from 'src/app/services/site.service';

@Component({
	selector: 'app-root',
	templateUrl: './root.component.html',
	styleUrls: ['./root.component.scss'],
	providers: [MessageService],
})
export class RootComponent implements OnInit, AfterViewInit, OnDestroy {
	public loaderVisible: boolean = false;
	public loaderIds: Array<{ key: string; observable: SubscriptionLike }> = [];
	private destroy$: Subject<boolean> = new Subject();
	constructor(private siteService: SiteService, private messageService: MessageService) {}

	ngOnInit(): void {}

	ngAfterViewInit(): void {
		this.siteService.onShowLoader.pipe(takeUntil(this.destroy$)).subscribe((id) => {
			if (this.loaderIds.filter((l) => l.key == id).length > 0) {
				//if hide has already fired...
				return;
			}
			let obs = timer(1000);
			this.loaderIds.push({
				key: id,
				observable: obs.pipe(take(1)).subscribe((val) => {
					this.loaderVisible = true;
				}),
			});
		});
		this.siteService.onHideLoader.pipe(takeUntil(this.destroy$)).subscribe((id) => {
			let ldr = this.loaderIds.filter((o) => o.key == id);
			if (ldr.length > 0) {
				if (ldr[0].observable) {
					ldr[0].observable.unsubscribe();
				}
				ldr[0].observable = null;
			} else {
				this.loaderIds.push({ key: id, observable: null });
			}

			if (this.loaderIds.filter((x) => x.observable != null).length == 0) {
				this.loaderVisible = false;
			}
			this.loaderIds = this.loaderIds.filter((o) => o.key != id);
		});
		this.siteService.onMessageSet.pipe(takeUntil(this.destroy$)).subscribe((msg) => this.messageService.add(msg));
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.complete();
	}
}
