
export class Report {
	public name: string;
	public reportType: number;
	public reportName: string;
	public parameters: ReportParameter[];
}

export class ReportParameter {
	public name: string;
	public key: string;
	public type: string;
	public value?: any;
	public required?: boolean;
	public options?: Array<{label: string, value: any}>;
	public api?: {method: string, text: string, value: string};
}

export class ReportData {
	public reportType: number;
	public employerID: string;
	public beginDate: Date;
	public endDate: Date;

	constructor(o?: any) {
		Object.assign(this, o);
	}
}

export const ReportDefinitions = [
	{
		name: 'Employer Sessions',
		reportType: 1,
		reportName: 'Employer_Sessions',
		parameters: [
			{ name: 'Start Date', type: 'date', key: 'beginDate', required: true },
			{ name: 'End Date', type: 'date', key: 'endDate', required: false },
			{ name: 'Employer', type: 'select', key: 'employerID', required: false, api: { method: 'getEmployers', text: 'employerName', value: 'employerID' } }
		]
	}, {
		name: 'Employer Engagements',
		reportType: 2,
		reportName: 'Employer_Engagements',
		parameters: [
			{ name: 'Start Date', type: 'date', key: 'beginDate', required: true },
			{ name: 'End Date', type: 'date', key: 'endDate', required: false },
			{ name: 'Employer', type: 'select', key: 'employerID', required: false, api: { method: 'getEmployers', text: 'employerName', value: 'employerID' } }
		]
	}, {
		name: 'Employer Engagement Details',
		reportType: 3,
		reportName: 'Employer_Engagement_Details',
		parameters: [
			{ name: 'Start Date', type: 'date', key: 'beginDate', required: true },
			{ name: 'End Date', type: 'date', key: 'endDate', required: false },
			{ name: 'Employer', type: 'select', key: 'employerID', required: false, api: { method: 'getEmployers', text: 'employerName', value: 'employerID' } }
		]
	}, {
		name: 'Engagements',
		reportType: 4,
		reportName: 'Engagements',
		parameters: [
			{ name: 'Start Date', type: 'date', key: 'beginDate', required: true },
			{ name: 'End Date', type: 'date', key: 'endDate', required: false },
		]
	}, {
		name: 'Engagement Details',
		reportType: 5,
		reportName: 'Engagement_Details',
		parameters: [
			{ name: 'Start Date', type: 'date', key: 'beginDate', required: true },
			{ name: 'End Date', type: 'date', key: 'endDate', required: false },
		]
	}
]
