import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ContentCard, ContentCardContainer } from 'src/app/models/content-card';
import { Employer, EmployerContent } from 'src/app/models/employer';
import { HeaderButton } from 'src/app/models/header-button';
import { AdminService } from 'src/app/services/admin.service';
import { DataService } from 'src/app/services/data.service';
import { SiteService } from 'src/app/services/site.service';
import { DocTag, Document } from 'src/app/models/document';
import { Content } from 'src/app/models/content';
import { FileUpload } from 'primeng/fileupload';
import { forkJoin, Observable } from 'rxjs';
import { IPendingChangesComponent } from 'src/app/guards/pending-changes.guard';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HelpersService } from 'src/app/services/helpers.service';
@Component({
  selector: 'app-employer',
  templateUrl: './employer.component.html',
  styleUrls: ['./employer.component.scss']
})
export class EmployerComponent implements OnInit, OnDestroy, IPendingChangesComponent {

	@ViewChild('censusUploadField') censusUploadField: FileUpload;
	public activeTab: string = '';
	public employer: Employer;
	public videoCards: Array<ContentCard> = [];
	public topicCards: Array<ContentCard> = [];
	public showAddCardDialog: boolean = false;
	public showEditCardDialog: boolean = false;
	public addingCardContainerID: number = 0;
	public editingCard: ContentCard;
	public existingCards: Array<string> = [];
	public cardsToExclude: Array<string> = []; //these are the cards where you hit "delete", but haven't saved changes yet
	public cardsToDelete: Array<string> = []; //these are the cards that are being deleted permanently

	public showReorderDialog: boolean = false;
	public cardsToReorder: Array<ContentCard>;
	public reorderingCardTypeId: number;
	public showAddNewDocumentDialog: boolean = false;
	public showEditDocumentDialog: boolean = false;
	public showDeleteDocumentConfirmation: boolean = false;
	public showRemoveDocumentConfirmation: boolean = false;
	public deletingDocumentId: string;
	public editingDocument: Document;
	public allDocuments: Array<Document> = [];
	public documentsToShow: Array<Document> = [];
	public selectedTagIds: Array<string> = [];
	public allTags: Array<DocTag> = [];

	public editingField: string;
	public contentField: string;

	public showDeleteEmployerConfirmation: boolean = false;
	public showDeleteCardConfirmation: boolean = false;
	public showSaveChangesConfirmation: boolean = false;
	public deletingCardId: string;

	public form: FormGroup;
	public originalContent: EmployerContent;
	public originalPath: string;
	public showPathUpdateConfirmation: boolean = false;

	constructor(private siteService: SiteService, private route: ActivatedRoute, private dataService: DataService, private adminService: AdminService, private router: Router, private fb: FormBuilder, private helpersService: HelpersService) { }

    get isSuperAdmin(): boolean {
        return this.adminService.isSuperAdmin;
    }

	ngOnInit(): void {
		this.route.paramMap.subscribe(p => {
			let tab = p.get('tab');
			if(!tab) {
				tab = this.route.snapshot.data['activeSection'] || 'configure';
			}
			this.setTab(tab);
			let id = p.get('id');
			if(id == 'new') {
				this.siteService.pageTitle = 'New Employer';
				this.employer = new Employer({ employerID: crypto.randomUUID() });
				//this.adminService.setEmployer(this.employer); //don't want to do this here, since it gives links in the left nav
				this.createForm();
				//for new employers, remove the preview button
				this.siteService.headerButtons = [
					new HeaderButton({ text: 'Save', class: 'success', callback: () => this.save().catch() }),
					new HeaderButton({ text: 'Delete', class: 'danger', callback: () => this.delete() })
				];
			} else {
				this.refreshEmployer(id);
			}
			this.siteService.backButton = { title: 'Back to Employers', link: '/admin/employers' };
		});
	}

	refreshEmployer(id?:string): void {
		this.videoCards = [];
		this.topicCards = [];
		this.cardsToExclude = []; //resetting these in case you opted to discard changes
		this.cardsToDelete = [];
		this.dataService.getEmployer(id).subscribe((e) => {
			this.siteService.pageTitle = e.employerName;
			this.employer = new Employer({ ...e, employerID: id });
			this.employer.qrCode = `${this.employer.qrCode}&r=${Math.random()}`
			this.originalPath = this.employer.path;
			this.adminService.setEmployer(this.employer);
			this.originalContent = new EmployerContent(structuredClone(this.employer.employerContent));
			//this.originalContent = new EmployerContent(JSON.parse(JSON.stringify(this.employer.employerContent)));
			const videoCardContainer = this.employer.employerCards.cardContainers.find(c => c.cardContainerID == 1);
			const topicCardContainer = this.employer.employerCards.cardContainers.find(c => c.cardContainerID == 2);
			if(videoCardContainer) {
				this.videoCards = [ ...videoCardContainer.cards.map(c => new ContentCard({ ...c, cardContainerID: 1 }))];
			} 
			if(topicCardContainer) {
				this.topicCards = [ ...topicCardContainer.cards.map(c => new ContentCard({ ...c, cardContainerID: 2 }))];
			}
			this.refreshDocs();
			this.createForm();

		});			
	}

	refreshQrCode() {
		this.dataService.getEmployer(this.employer.employerID).subscribe((e) => {
			this.employer.qrCode = `${e.qrCode}&r=${Math.random()}`
		});
	}
	refreshDocs() {
		forkJoin({
			docs: this.dataService.getDocuments(this.employer.employerID), 
			tags: this.dataService.getTags()
		}).subscribe(({docs, tags}) => {
			this.allTags = (tags as Array<DocTag>);
			this.allDocuments = docs.map((d:any) => new Document(d)).sort((a, b) => a.docTitle.localeCompare(b.docTitle));
			this.allDocuments.forEach(d => d.fullTags = d.docTags.map(t => this.allTags.find(tag => tag.tagID == t)));
			this.filterDocuments();
		});

	}

	createForm() {
		this.form = this.fb.group({
			employerName: [this.employer.employerName, Validators.required],
			path: [this.employer.path, Validators.required],
			logo: [this.employer.employerLogoUploadID],
		});

	}
	setTab(name: string): void {
		this.activeTab = name;
		switch (name) {
			case 'configure':
				this.siteService.headerButtons = [
					new HeaderButton({ text: 'Update Docs', class: 'neutral', callback: () => this.generateEmployerDocs() }),
					new HeaderButton({ text: 'Preview', class: 'neutral', callback: () => this.preview() }),
					new HeaderButton({ text: 'Save', class: 'success', callback: () => this.save().catch() }),
					new HeaderButton({ text: 'Delete', class: 'danger', callback: () => this.delete() })
				];
				break;
			case 'customizations':
				this.siteService.headerButtons = [
					new HeaderButton({ text: 'Preview', class: 'neutral', callback: () => this.preview() }),
					new HeaderButton({ text: 'Save', class: 'success', callback: () => this.save().catch() })
				];
				break;
			case 'video-cards':
				this.siteService.headerButtons = [
					new HeaderButton({ text: 'Preview', class: 'neutral', callback: () => this.preview() }),
					new HeaderButton({ text: 'Add New', class: 'neutral', callback: () => this.addNewVideoCard() }),
					new HeaderButton({ text: 'Reorder', class: 'neutral', callback: () => this.reorderCards(this.videoCards, 1) }),
					new HeaderButton({ text: 'Save', class: 'success', callback: () => this.save().catch() })
				];
				break;
			case 'topic-cards':
				this.siteService.headerButtons = [
					new HeaderButton({ text: 'Preview', class: 'neutral', callback: () => this.preview() }),
					new HeaderButton({ text: 'Add New', class: 'neutral', callback: () => this.addNewTopicCard() }),
					new HeaderButton({ text: 'Reorder', class: 'neutral', callback: () => this.reorderCards(this.topicCards, 2) }),
					new HeaderButton({ text: 'Save', class: 'success', callback: () => this.save().catch() })
				];
				break;
				case 'library':
					this.siteService.headerButtons = this.adminService.isSuperAdmin ? [
						new HeaderButton({ 
							text: 'Add Document', 
							class: 'neutral', 
							callback: () => this.addDocument() 
						}),
					] : [];
					break;

		}
				
	}

	canDeactivate(): boolean | Observable<boolean> {
		switch (this.activeTab) {
			case 'configure':
				return !this.form.dirty;
			case 'customizations':
				//return this.employer.employerContent.contentCategories.reduce((acc, cur) => [ ...acc, ...cur.content.map(c =>  c.textContent != c.defaultTextContent || c.fileContent ) ], []).length > 0;
				return this.originalContent.isMatch(this.employer.employerContent);
			case 'video-cards':
				return this.cardsToExclude.length == 0 && this.cardsToDelete.length == 0;
			case 'topic-cards':
				return this.cardsToExclude.length == 0 && this.cardsToDelete.length == 0;
			case 'library':
				return true;
			default:
		}
		return true;
	}

	async copyImage() {
		const qrPath = this.employer.qrCode;
		const img = await fetch(qrPath);
		const blob = await img.blob();
		const data = [ new ClipboardItem({ [blob.type]: blob }) ];
		await navigator.clipboard.write(data);
	
	}
	save(skipPathCheck: boolean = false): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			switch (this.activeTab) {
				case 'configure':
					if(!skipPathCheck && this.form.controls['path'].value != this.originalPath) {
						this.showPathUpdateConfirmation = true;
						return;
					}
					if(!this.form.invalid) {
						this.employer.employerName = this.form.controls['employerName'].value;
						this.employer.path = this.form.controls['path'].value;
						this.employer.employerLogoUploadID = this.form.controls['logo'].value
						this.dataService.saveEmployerConfig(this.employer.employerID, this.employer.employerName, this.employer.path, this.employer.employerLogoUploadID).subscribe(() => {
							this.siteService.setMessage('Content saved successfully', 'success');
							this.siteService.pageTitle = this.employer.employerName;
							this.form.markAsPristine();
							this.adminService.setEmployer(this.employer); //this is so the left nav updates if you're addng a new employer
							resolve(true);
							this.refreshQrCode();
							//replace preview button (may have been removed for new employers)
							this.siteService.headerButtons = [
								new HeaderButton({ text: 'Preview', class: 'neutral', callback: () => this.preview() }),
								new HeaderButton({ text: 'Save', class: 'success', callback: () => this.save().catch() }),
								new HeaderButton({ text: 'Delete', class: 'danger', callback: () => this.delete() })
							];
			
						});
					} else {
						this.siteService.setMessage('Please enter values for all required fields', 'error');
						reject(false);
					}
					break;
				case 'customizations':
					this.employer.employerContent.contentCategories.forEach((cat) => cat.content.forEach((c) => { c.error = null; })); //clear out any errors
					let content = this.employer.employerContent.contentCategories.reduce((acc, cur) => [ ...acc, ...cur.content.map(c =>  ({ contentID: c.contentID, textContent: c.textContent ? String(c.textContent) : null, fileUploadID: c.fileUploadID })) ], []);
					content = content.filter(c => c.textContent || c.fileUploadID);
					this.dataService.saveEmployerContent(this.employer.employerID, content).subscribe({
						next: () => {
							this.siteService.setMessage('Content saved successfully', 'success');
							this.originalContent = new EmployerContent(structuredClone(this.employer.employerContent)); //reset original so we don't get the prompt when you move away
							resolve(true);	
						},
						error: (err) => {
							err.error.errors.forEach((e:any) => {
								this.findContent(e.listIdentifier).error = e.errorMessage;
							});
							setTimeout(() => {
								document.getElementsByClassName('has-error')[0].scrollIntoView();
							}, 20);
							setTimeout(() => {
								window.scrollBy({top: -150, behavior: 'smooth'});
							}, 30);
							reject(false);
						}
					});
					break;
				case 'video-cards':
					let vcards = this.videoCards.filter(c => !this.cardsToExclude.includes(c.cardID)).map((c, i) => ({ cardID: c.cardID, sortOrder: i }));
					
					this.dataService.saveEmployerCards(this.employer.employerID, 1, vcards).subscribe(() => {
						forkJoin(this.cardsToDelete.map(c => this.dataService.deleteCard(c))).subscribe(() => this.cardsToDelete = []);
						this.refreshEmployer(this.employer.employerID);
						this.cardsToExclude = [];
						this.siteService.setMessage('Content saved successfully', 'success');
						resolve(true);	
					});
					break;
				case 'topic-cards':
					let tcards = this.topicCards.filter(c => !this.cardsToExclude.includes(c.cardID)).map((c, i) => ({ cardID: c.cardID, sortOrder: i }));
					this.dataService.saveEmployerCards(this.employer.employerID, 2, tcards).subscribe(() => {
						forkJoin(this.cardsToDelete.map(c => this.dataService.deleteCard(c))).subscribe(() => this.cardsToDelete = []);
						this.refreshEmployer(this.employer.employerID);
						this.cardsToExclude = [];
						this.siteService.setMessage('Content saved successfully', 'success');
						resolve(true);	
					});
					break;
				default:
					break;
			}
		});
	}

	preview(force: boolean = false) {
		if(force || this.canDeactivate()) {
			const previewUrl = this.helpersService.getEmployerUrl(this.employer.path);
			window.open(previewUrl, "_blank");
			this.showSaveChangesConfirmation = false;
		} else {
			this.showSaveChangesConfirmation = true;
		}
	}
	setCheckboxValue(e:any, content: Content) {
		content.textContent = e.checked ? 'true' : 'false';
		content.boolContent = e.checked;
	}

	findContent(contentID: string) {
		//ai is brilliant!  Flatten all the categories, and find the content with the matching ID
		return this.employer.employerContent.contentCategories.reduce((acc, cur) => [ ...acc, ...cur.content ], []).find(c => c.contentID == contentID);
	}
	delete() {
		this.showDeleteEmployerConfirmation = true;
	}
	doDeleteEmployer() {
		this.dataService.deleteEmployer(this.employer.employerID).subscribe(() => {
			this.siteService.setMessage('Employer deleted successfully', 'success');
			this.showDeleteEmployerConfirmation = false;
			this.form.markAsPristine(); //to prevent the "are you sure you want to leave" prompt
			this.router.navigate(['/admin', 'employers']);
		});
	}
	deleteLogo() {
		this.form.controls['logo'].setValue(null);
		//this.employer.employerLogoUploadID = null;
		this.employer.logo = null;
	}

	addDocument() {
		this.showAddNewDocumentDialog = true;
	}

	closeAddDocumentDialog() {
		this.showAddNewDocumentDialog = false;
		this.refreshDocs();
	}

	deleteDocument(doc: Document) {
		this.deletingDocumentId = doc.docID;
		this.showDeleteDocumentConfirmation = true;
	}
	removeDocument(doc: Document) {
		this.deletingDocumentId = doc.docID;
		this.showRemoveDocumentConfirmation = true;
	}
	editDocument(doc: Document) {
		this.editingDocument = new Document({ ...doc });
		this.showEditDocumentDialog = true;
	}
	closeEditDocumentDialog() {
		this.showEditDocumentDialog = false;
	}
	onDocumentSaved(doc: Document) {
		doc.fullTags = doc.docTags.map(t => this.allTags.find(tag => tag.tagID == t));
		this.allDocuments = this.allDocuments.map(d => d.docID == doc.docID ? doc : d);
		this.documentsToShow = [ ...this.allDocuments ];
		this.filterDocuments();
	};

	filterDocuments() {
		this.documentsToShow = [ ...this.allDocuments.filter(d => this.selectedTagIds.length == 0 || d.docTags.some(t => this.selectedTagIds.includes(t))) ];
	}

	onConfirmClick({ action, data }: {action: string, data: any}) {
		switch(action) {
			case 'Delete':
				this.doDeleteEmployer();
				break;
			case 'Cancel':
				this.showDeleteEmployerConfirmation = false;
				this.showDeleteCardConfirmation = false;
				this.showDeleteDocumentConfirmation = false;
				this.showRemoveDocumentConfirmation = false;
				if(this.showPathUpdateConfirmation) {
					this.form.controls['path'].setValue(this.originalPath);
				}
				this.showPathUpdateConfirmation = false;
				break;
			case 'Hide Temporarily':
				this.cardsToExclude.push(data);
				this.showDeleteCardConfirmation = false;
				break;
			case 'Delete Permanently':
				this.cardsToDelete.push(data);
				this.cardsToExclude.push(data);
				this.showDeleteCardConfirmation = false;
				break;
			case 'Save Changes':
				this.save().then(() => this.preview());
				break;
			case 'Preview without Saving':
				this.preview(true);
				break;
			case 'Remove Document':
				this.allDocuments = this.allDocuments.filter(d => d.docID != data);
				this.dataService.saveEmployerDocuments(this.employer.employerID, this.allDocuments).subscribe(() => {
					this.siteService.setMessage('Document deleted removed', 'success');
					this.showRemoveDocumentConfirmation = false;
					this.allDocuments = this.allDocuments.filter(d => d.docID != data);
					this.filterDocuments();
				});
				break;
			case 'Delete Document': 
				this.dataService.deleteDocument(data).subscribe(() => {
					this.siteService.setMessage('Document deleted successfully', 'success');
					this.showDeleteDocumentConfirmation = false;
					this.allDocuments = this.allDocuments.filter(d => d.docID != data);
					this.filterDocuments();
				});
				break;
			case 'Continue Saving': //after path warning
				this.save(true).then(() => this.showPathUpdateConfirmation = false);
				break;

		}
	}
	setContentField(id: string) {
		this.contentField = id;
		setTimeout(() => {
			this.contentField = (this.contentField == id ? null : this.contentField);
		}, 6000);
	}
	setCardInclusion({include, cardID}: {include: boolean, cardID: string}): void {
		let thisCard = this.videoCards.find(c => c.cardID == cardID) || this.topicCards.find(c => c.cardID == cardID);
		if(include) {
			this.cardsToExclude = this.cardsToExclude.filter(c => c != cardID);
		} else {
			if(thisCard.employerID) {
				//this is an employer-only card- prompt to delete permanently
				this.showDeleteCardConfirmation = true;
				this.deletingCardId = cardID;
			} else {
				//this is a global card, all they can do is exclude
				this.cardsToExclude.push(cardID);
			}
		}
	}

	addNewVideoCard(): void {
		this.addingCardContainerID = 1;
		this.existingCards = this.videoCards.map(c => c.cardID);
		this.showAddCardDialog = true;
	}
	addNewTopicCard(): void {
		this.addingCardContainerID = 2;
		this.existingCards = this.topicCards.map(c => c.cardID);
		this.showAddCardDialog = true;
	}
	saveNewCards() {}
	ngOnDestroy(): void {
		this.adminService.setEmployer(null);
	}

	onLogoUpload({ filePath }:any) {
		this.form.controls['logo'].setValue(this.employer.employerLogoUploadID);
		this.employer.logo = filePath;
	}

	generateEmployerDocs() {
		this.dataService.generateEmployerDocs(this.employer.employerID).subscribe(() => {
			this.siteService.setMessage('Document update successful', 'success');
		});
	}

	uploadContentImage(e: any, contentCategoryID: number, contentId: string) {
		const id = crypto.randomUUID();
		this.dataService.uploadFile(id, e.currentFiles[0]).subscribe((f) => {
			const content = this.employer.employerContent.contentCategories.find(c => c.contentCategoryID == contentCategoryID).content.find(c => c.contentID == contentId)
			content.fileUploadID = id;
			//content. = e.currentFiles[0].objectURL; //TODO: set image to preview
		});
	}

	onFileUpload(e:any) {
		
	}
	closeAddCardDialog() {
		this.showAddCardDialog = false;
		this.refreshEmployer(this.employer.employerID);
	}

	resetText(content: Content) {
		content.textContent = content.defaultTextContent;
	}

	editCard(card: ContentCard): void {
		this.editingCard = card;
		this.showEditCardDialog = true;
	}
	closeEditCardDialog() {
		this.showEditCardDialog = false;
		this.refreshEmployer(this.employer.employerID);
	}

	reorderCards(cards: Array<ContentCard>, typeId: number): void {
		this.cardsToReorder = [ ...cards ];
		this.reorderingCardTypeId = typeId;
		this.showReorderDialog = true;
	}

	closeReorderDialog() {
		this.showReorderDialog = false;		
	}
	saveCardOrder() {
		let cards = this.cardsToReorder.map((c, i) => ({ ...c, sortOrder: i }));
		this.dataService.saveEmployerCards(this.employer.employerID, this.reorderingCardTypeId, cards.map(c => ({ ...c, resetCard: null }))).subscribe(() => {
			if(this.reorderingCardTypeId == 1) this.videoCards = [ ...cards.map(c => new ContentCard(c)).sort((a,b) => a.sortOrder - b.sortOrder) ];
			if(this.reorderingCardTypeId == 2) this.topicCards = [ ...cards.map(c => new ContentCard(c)).sort((a,b) => a.sortOrder - b.sortOrder) ];
			this.cardsToReorder = null;
			this.reorderingCardTypeId = null;
			this.showReorderDialog = false;
		});
	}

	uploadCensusData(e:any) {
		this.dataService.uploadCensusData(this.employer.employerID, e.currentFiles[0]).subscribe(() => {
			this.siteService.setMessage('Census data uploaded successfully', 'success');
		}, (err:any) => {
			this.siteService.setMessage('Error uploading census data: ' + err.error, 'error', 10000);
			this.censusUploadField.clear();
		});
	}

	clearCensusFile() {
		this.censusUploadField.clear();
	}
}
