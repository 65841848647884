import { EventEmitter, Injectable } from "@angular/core";
import { jwtDecode } from "jwt-decode";
import { Employer } from "../models/employer";

@Injectable({
	providedIn: 'root'
})
export class AdminService {

	public onEmployerSet: EventEmitter<Employer> = new EventEmitter();

	public get isSuperAdmin() {
		return this.role == 'SuperAdmin';
	}
	public get isAdmin() {
		return !!this.token;
	}

	public get token() {
		return window.sessionStorage.getItem('token');
	}
	public set token(val: string) {
		window.sessionStorage.setItem('token', val)
	}

	public get role() {
		return (jwtDecode(this.token) as any)['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
	}

	public get employerId() {
		return (jwtDecode(this.token) as any)['EmployerID'];
	}

	public setEmployer(employer: Employer) {
		this.onEmployerSet.emit(employer);
	}

}


