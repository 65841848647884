import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContentCard } from 'src/app/models/content-card';
import { DataService } from 'src/app/services/data.service';
import { SiteService } from 'src/app/services/site.service';

@Component({
  selector: 'app-global-cards',
  templateUrl: './global-cards.component.html',
  styleUrls: ['./global-cards.component.scss']
})
export class GlobalCardsComponent {

	public activeTab: string = 'video-cards';
	public videoCards: Array<ContentCard> = [];
	public topicCards: Array<ContentCard> = [];

	public editingCard: ContentCard = null;
	public showEditCardModal: boolean = false;
	public showDeleteCardConfirmation: boolean = false;
	private deletingCardId: string = null;
	public modalTitle: string = '';

	constructor(private siteService: SiteService, private route: ActivatedRoute, private dataService: DataService) { }

	ngOnInit(): void {
		this.siteService.pageTitle = 'Global Cards';
		this.route.paramMap.subscribe(p => {
			this.setTab(p.get('tab') || 'video-cards');
		});
		this.siteService.headerButtons = [
			{ text: 'Add New', class: 'success', callback: this.addNewCard.bind(this) },
		];
	}

	setTab(name: string): void {
		this.activeTab = name;
		switch(name) {
			case 'video-cards':
				this.dataService.getAllCards(1).subscribe((cards) => {
					this.videoCards = cards.map(c => new ContentCard(c)).filter(c => c.employerID == null);
				});
				break;
			case 'topic-cards':
				this.dataService.getAllCards(2).subscribe((cards) => {
					this.topicCards = cards.map(c => new ContentCard(c)).filter(c => c.employerID == null);
				});
				break;
		}
		this.siteService.headerButtons = [
			{ text: 'Add New', class: 'success', callback: this.addNewCard.bind(this) },
		];
	}

	onCardEdited(card: ContentCard): void {
		this.editingCard = new ContentCard({ ...card });
		this.modalTitle = 'Edit ' + (this.activeTab == 'video-cards' ? 'Video' : 'Topic') + ' Card';
		this.showEditCardModal = true;
	}

	addNewCard() {
		this.editingCard = new ContentCard({ cardID: crypto.randomUUID(), cardContainerID: this.activeTab == 'video-cards' ? 1 : 2 }); 
		this.modalTitle = 'Add New ' + (this.activeTab == 'video-cards' ? 'Video' : 'Topic') + ' Card';
		this.showEditCardModal = true
	}
	
	closeEditCardDialog(refresh: boolean): void {
		this.showEditCardModal = false;
		if(refresh) {
			this.setTab(this.activeTab);
		}
	}

	onCardDeleted({ cardID }: any) {
		this.deletingCardId = cardID;
		this.showDeleteCardConfirmation = true;
	}
	doDeleteCard() {
		this.dataService.deleteCard(this.deletingCardId).subscribe(() => {
			switch(this.activeTab) {
				case 'video-cards':
					this.videoCards = this.videoCards.filter(c => c.cardID != this.deletingCardId);
					break;
				case 'topic-cards':
					this.topicCards = this.topicCards.filter(c => c.cardID != this.deletingCardId);
			}
			this.deletingCardId = null;
			this.showDeleteCardConfirmation = false;
		});
	}

	cancelDelete() {
		let cards = this.activeTab == 'video-cards' ? this.videoCards : this.topicCards;
		let card = cards.find(c => c.cardID == this.deletingCardId);
		card.resetCard.next();
		this.showDeleteCardConfirmation = false;
	}
	onConfirmClick({action}: {action: string, data: any}) {
		if(action == 'Delete') {
			this.doDeleteCard();
		} else {
			this.cancelDelete();
		}
	}
}
