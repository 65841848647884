import { Subject } from "rxjs";

export class ContentCard {
	public cardID: string;
	public tag: string;
	public title: string;
	public imageFile: string;
	public videoFile: string;
	public heading: string;
	public cardContent: string;
	public cardContentDetail: string;
	public sortOrder: number;
	public employerID: string | null;
	public cardContainerID: number;

	public imageUploadID: string;
	public videoUploadID: string;

	public isOpen: boolean = false;
	public added: boolean = false;

	constructor(o?: any) {
		Object.assign(this, o);
	}
	get imageFileQuoted() {
		return `"${this.imageFile}"`;
	}

	public resetCard: Subject<void> = new Subject<void>();
}

export class ContentCardContainer {
	public cardContainerDesc: string;
	public cardContainerID: number;
	public cards: Array<ContentCard>;

	constructor(o?: any) {
		if(o.cards) {
			o.cards = o.cards.map((c:any) => new ContentCard(c));
		}
		Object.assign(this, o);
	}
	
}