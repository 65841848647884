<div class="add-card">
	<div class="tabset">
		<div class="tab" (click)="setActiveTab('existing')" [ngClass]="{ active: activeTab == 'existing' }">Existing</div>
		<div class="tab" (click)="setActiveTab('new')" [ngClass]="{ active: activeTab == 'new' }">New</div>
	</div>
	<div class="tabs">
		<div class="tab-content" *ngIf="activeTab == 'existing'">
			<div class="card-container">
				<p-carousel [value]="allCards" [numVisible]="3" [circular]="false" (onPage)="onPageChange($event)" [ngClass]="{'first-page': carouselIndex == 0, 'last-page': (carouselIndex == allCards.length - PAGE_SIZE) || (allCards.length <= PAGE_SIZE)}">
					<ng-template let-card pTemplate="item">
						<app-admin-card [cardData]="card" [canDelete]="false" [canEdit]="false" [canAdd]="true" (onAddRemoveCard)="addCard($event)"></app-admin-card>
					</ng-template>
				</p-carousel>		
			</div>
		</div>
		<div class="tab-content" *ngIf="activeTab == 'new'">
			<div class="new-card">
				<div class="card-data" [formGroup]="form">
					<div class="field">
						<label for="newCardTag">Tag</label>
						<input pInputText type="text" id="newCardTag" [formControlName]="'tag'">
					</div>
					<div class="field">
						<label for="newCardTitle">Title</label>
						<input pInputText type="text" id="newCardTitle" [formControlName]="'title'">
					</div>
					<div class="field">
						<label for="newCardContent">Content</label>
						<textarea pInputTextarea id="newCardContent" [formControlName]="'cardContent'"></textarea>
					</div>
					<div class="field" *ngIf="cardContainerID == 2">
						<label for="newCardContentLong">Content</label>
						<textarea pInputTextarea id="newCardContentLong" [formControlName]="'cardContentDetail'"></textarea>
					</div>
					<div class="field">
						<label for="newCardImage">Image</label>
						<app-file-upload [(id)]="newCard.imageUploadID" [valid]="isValid('imageUploadID')" (fileUploaded)="onImageFileChange($event, 'image')" [fileUrl]="newCard.imageFile"></app-file-upload>
					</div>
					<div class="field" *ngIf="cardContainerID == 1">
						<label for="newCardVideo">Video</label>
						<app-file-upload [(id)]="newCard.videoUploadID" [valid]="isValid('imageUploadID')" [allowedTypes]="'video/*'" (fileUploaded)="onImageFileChange($event, 'video')" [fileUrl]="newCard.videoFile"></app-file-upload>
					</div>
				</div>
				<div class="preview">
					<app-video-card [cardData]="newCard" *ngIf="cardContainerID == 1"></app-video-card>
					<app-topic-card [cardData]="newCard" *ngIf="cardContainerID == 2"></app-topic-card>
				</div>
			</div>
		</div>
	</div>
	<div class="actions">
		<div class="button success" (click)="saveNewCards()" *ngIf="activeTab == 'existing'">Save</div>
		<div class="button success" (click)="saveNewCard()" *ngIf="activeTab == 'new'">Save</div>
		<div class="button neutral" (click)="close()">Close</div>			
	</div>

</div>