<div class="card">
	<div class="icon">
		<img [src]="'/assets/images/' + icon + '.svg'" />
	</div>
	<div class="head">
		<ng-container *ngIf="link"><a [href]="link" target="_blank" [interact]="{engagementTypeID: 4, resource: link}">{{head}}</a></ng-container>
		<ng-container *ngIf="!link">{{head}}</ng-container>
	</div>
	<div class="body">{{body}}</div>
</div>
