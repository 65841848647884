
export class User {
	public userID: string;
    public activationDate: Date;
    public lastLoginDate: Date;
    public role: string;
    public emailAddress: string;
    public password: string;
    public firstName: string;
    public lastName: string;
    public employerID: string | null;

	public employerName: string;

	constructor(o?: any) {
		Object.assign(this, o);
	}
}