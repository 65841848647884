
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SessionService } from '../services/session.service';
import { SiteService } from '../services/site.service';
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private sessionService: SessionService, private router: Router, private siteService: SiteService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
		switch(err.status) {
			case 401:
			case 403:
				// auto logout if 401 response returned from api
				this.sessionService.logout();
				this.router.navigate(["admin", "login" ], { queryParams: { returnUrl: this.router.url } });
				break;
			case 405:
			case 500:
				this.siteService.setMessage("There was a problem processing your request.  Please try again later.", "error");
				break;
		}
        return throwError(err);
      })
    );
  }
}
