import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Document } from 'src/app/models/document';
import { DataService } from 'src/app/services/data.service';
import { SiteService } from 'src/app/services/site.service';

@Component({
  selector: 'app-edit-document',
  templateUrl: './edit-document.component.html',
  styleUrls: ['./edit-document.component.scss']
})
export class EditDocumentComponent {

	@Input() document: Document;
	@Output() onClose: EventEmitter<any> = new EventEmitter();
	@Output() onSave: EventEmitter<Document> = new EventEmitter();

	public form: FormGroup;

	constructor(private dataService: DataService, private siteService: SiteService, private fb: FormBuilder) { }
	
	ngOnInit() {
		this.initForm();
		this.form.valueChanges.subscribe(() => {
			this.document = new Document({ ...this.document, ...this.getNonNullProperties<Document>(this.form.value) });
		});
	}
	
	initForm() {
		this.form = this.fb.group({
			docID: [this.document.docID],
			employerID: [this.document.employerID],
			docTitle: [this.document.docTitle, Validators.required],
			docAuthor: [this.document.docAuthor, Validators.required],
			docFile: [this.document.docFile],
			docUploadID: [this.document.docUploadID, Validators.required]
		});
	}

	saveDocument() {
		this.form.markAllAsTouched();
		if(this.form.valid) {
		this.dataService.saveDocument(this.document).subscribe(() => {
			this.siteService.setMessage('Document saved successfully', 'success');
			this.onSave.emit(new Document(this.document));
			this.close();
		});
		} else {
			this.siteService.setMessage('Please fill in all required fields', 'error');
		}
	}	

	close() {
		// Close the dialog
		this.onClose.emit();
	}

	onImageFileChange({ filePath }: any) {
		this.document.docFile = filePath;
		this.form.get('docUploadID').setValue(this.document.docUploadID);
	}
	
	isValid(field:string) {
		return !this.form.get(field).touched || this.form.get(field).valid;
	}
	getNonNullProperties<T>(obj: T): Partial<T> {
		const result: Partial<T> = {};
	  
		for (const key in obj) {
		  if (obj.hasOwnProperty(key) && obj[key as keyof T] !== null && obj[key as keyof T] !== undefined) {
			result[key as keyof T] = obj[key as keyof T];
		  }
		}
	  
		return result;
	  }

	onFileRemoved() {
		this.document.docFile = null;
		this.document.docUploadID = null;
	}
}
