
<div class="users">
	<div class="header email">Email</div>
	<div class="header first-name">First Name</div>
	<div class="header last-name">Last Name</div>
	<div class="header access-level">Access Level</div>
	<div class="header enter-date">Enter Date</div>
	<div class="header last-login">Last Login</div>
	<ng-container *ngFor="let user of users">
		<div class="email" (click)="goTo(user)">{{user.emailAddress}}</div>
		<div class="first-name">{{user.firstName}}</div>
		<div class="first-name">{{user.lastName}}</div>
		<div class="first-name" *ngIf="user.employerName">{{user.employerName}}</div>
		<div class="first-name super-admin" *ngIf="!user.employerName">Super Admin</div>
		<div class="first-name">{{user.activationDate | date}}</div>
		<div class="first-name">{{user.lastLoginDate | date}}</div>
	</ng-container>
</div>
