import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { Interaction } from 'src/app/models/interaction';
import { AdminService } from 'src/app/services/admin.service';
import { DataService } from 'src/app/services/data.service';
import { SessionService } from 'src/app/services/session.service';

@Directive({
  selector: '[interact]'
})
export class InteractDirective implements AfterViewInit {
	@Input() interact: any;
	constructor(private el: ElementRef, private dataService: DataService, private sessionService: SessionService, private adminService: AdminService) {}

	ngAfterViewInit() {
		if(!!this.adminService.token) {
			return; // Don't log interactions if the user is an admin
		}
		if(this.el.nativeElement.tagName === 'VIDEO') {
			this.el.nativeElement.addEventListener('play', () => {
				let interaction = { ...this.interact, webSessionID: this.sessionService.sessionID, employerID: this.sessionService.employerID };
				if(interaction.engagementTypeID) {
					this.dataService.logInteraction(interaction).subscribe();
				}
			});

		} else {
			this.el.nativeElement.addEventListener('click', () => {
				let interaction = { ...this.interact, webSessionID: this.sessionService.sessionID, employerID: this.sessionService.employerID };
				if(interaction.engagementTypeID) {
					this.dataService.logInteraction(interaction).subscribe();
				}
			});
		}
	}
}
