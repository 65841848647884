import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { User } from 'src/app/models/user';
import { DataService } from 'src/app/services/data.service';
import { SiteService } from 'src/app/services/site.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent {

	public users: Array<User> = [];

	constructor(private dataService: DataService, private siteService: SiteService, private router: Router) {
		forkJoin({
			users: this.dataService.getUsers(),
			employers: this.dataService.getEmployers()
		}).subscribe(({ users, employers }) => {
			this.users = users.map((u) => {
				const employer = employers.find(e => e.employerID == u.employerID);
				return new User({ ...u, employerName: employer?.employerName });
			});
		});
		this.siteService.pageTitle = 'Users';
		this.siteService.headerButtons = [
			{ text: 'Add User', class: 'neutral', callback: () => this.addUser() }
		];
	}

	addUser() {
		this.router.navigate(['/admin/user/new']);
	} 
	goTo(user: User) {
		this.router.navigate(['/admin/user', user.userID]);
	}
}
