import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent {

	@Input() question: string = '';
	@Input() index: number = 0;

	public isOpen: boolean = false;
}
