<main>
	<div class="login-form">
		<div class="field">
			<label for="username">Username</label>
			<input pInputText type="text" [(ngModel)]="username" id="username">
		</div>
		<div class="field">
			<label for="password">Password</label>
			<p-password [(ngModel)]="password" id="password" (keyup.enter)="login()" [feedback]="false" [toggleMask]="true"></p-password>
		</div>
		<div class="actions">
			<div class="button neutral" (click)="login()">Login</div>
		</div>
	</div>	
</main>
