import { Injectable, inject } from "@angular/core";
import { CanDeactivate, CanDeactivateFn } from "@angular/router";
import { ConfirmationService } from "primeng/api";
import { Observable, of } from "rxjs";

export interface IPendingChangesComponent {
	canDeactivate: () => boolean | Observable<boolean>
	save: () => Promise<boolean>
}


export const PendingChangesGuard: CanDeactivateFn<IPendingChangesComponent> = (component: IPendingChangesComponent): Observable<boolean> => {
	const confirmationService = inject(ConfirmationService);
	return component.canDeactivate() ? of(true) : new Observable(subscriber => {
		confirmationService.confirm({
			message: "You have unsaved changes.",
			header: "Save Changes?",
			acceptLabel: "Save Changes", //switching these around to get them in the other order
			rejectLabel: "Discard Changes",
			acceptIcon: 'check',
			rejectIcon: 'times',
			rejectButtonStyleClass: 'danger',
			accept: () => {
				component.save().then(() => subscriber.next(true)).catch(() => subscriber.next(false))
			},
			reject: () => subscriber.next(true)
		})
	})
	
}
