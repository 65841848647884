
<div class="document">
	<div class="details">
		<h2>
			<a [href]="document.docFile" target="_blank">{{document.docTitle}}</a>
			<div class="tags">
				<span class="tag" *ngFor="let tag of document.fullTags">{{tag.tagDesc}}</span>
			</div>
		</h2>
		<ul>
			<li><span>Author: </span><span>{{document.docAuthor}}</span></li>
			<li><span>Doc Type: </span><span>{{document.fileType}}</span></li>
			<li *ngIf="document.lastUpdated"><span>Date: </span><span>{{document.lastUpdated | date: 'M/d/yyyy'}}</span></li>
		</ul>
	</div>
	<div class="actions">
		<ng-container *ngFor="let button of buttons">
			<ng-container [ngSwitch]="button">
				<div *ngSwitchCase="''"><div>&nbsp;</div></div> <!-- just a placeholder/spacer-->
				<div *ngSwitchCase="'edit'"><div class="button neutral" (click)="editDocument()">Edit</div></div>
				<div *ngSwitchCase="'remove'"><div class="button danger" (click)="removeDocument()">Remove</div></div>
				<div *ngSwitchCase="'delete'"><div class="button danger" (click)="deleteDocument()">Delete</div></div>
				<div *ngSwitchCase="'add'"><div class="button success" (click)="addDocument()">Add</div></div>
				<div *ngSwitchCase="'cancel'"><div class="button neutral" (click)="cancelAddDocument()">Cancel</div></div>
			</ng-container>
		</ng-container>
	</div>
</div>