import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Report, ReportDefinitions, ReportParameter } from 'src/app/models/report-data';
import { DataService } from 'src/app/services/data.service';
import { SiteService } from 'src/app/services/site.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent {

	public reports: Array<Report>;
	public selectedReport: Report;
	public form: FormGroup
	
	constructor(private fb: FormBuilder, private dataService: DataService, private siteService: SiteService) {}

	ngOnInit(): void {
		this.siteService.pageTitle = 'Reports';
		this.siteService.headerButtons = [
			{ text: 'Run Report', class: 'neutral', callback: () => this.runReport() }
		];
		this.reports = ReportDefinitions;
	}

	selectReport(r:Report) {
		let group:any = {};
		if(r?.parameters.length > 0) {
			
			r.parameters.forEach(p => {
				group[p.key] = new FormControl('');
				if(p.required) {
					group[p.key].Validators = [Validators.required];
				}
				if(p.type == 'select') {
					let apiCall;
					switch(p.api.method) {
						case 'getEmployers': apiCall = this.dataService.getEmployers; break;
						default: apiCall = this.dataService.getEmployers; break;
					}
					apiCall.apply(this.dataService).subscribe((resp: Array<any>) => {
						p.options = resp.map(r => ({ label: r[p.api.text], value: r[p.api.value] }));
						p.options.sort((a,b) => a.label < b.label ? -1 : (a.label > b.label ? 1 : 0))
					})
				}
			})
			this.form = this.fb.group(group);
		} else {
			this.form = this.fb.group({});
		}
	}

	setDate(date: any, key: string) {
		this.form.get(key).setValue(date);
	}
	runReport() {
		if(!this.selectReport) return;
		this.form.markAllAsTouched();
		if(this.form.valid) {
			let params: Array<{name: string, value: any}> = [];
			Object.keys(this.form.controls).forEach(key => {
				if(!this.form.controls[key].value) {
					params.push({name: key, value: null })
				} else {
					if(key == 'employerID') {
						params.push({name: key, value: this.form.controls[key].value.value})
					} else {
						params.push({name: key, value: this.form.controls[key].value})
					}
				}
			})
			let rptData = params.reduce((acc:any, p:any) => ({...acc, [p.name]: p.value}), { reportType: this.selectedReport.reportType });
			this.dataService.runReport(rptData).subscribe((data: any) => {
				if(data.byteLength == 0) {
					this.siteService.setMessage("There was no data returned for your report", "error");
					return;
				}
				const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'; 
				const blob = new Blob([data], { type: contentType });
				var a = document.createElement('a');
				document.body.appendChild(a);
				a.style.display = "none";
				const url = window.URL.createObjectURL(blob);
				a.href = url;
				a.download = this.selectedReport.name + ".xlsx";
				a.click();
				URL.revokeObjectURL(a.href);
				a.remove();
				this.siteService.setMessage("Your report is being downloaded.");
			}, () => {
				this.siteService.setMessage("There was no data returned for your report", "error");
			})
		}
	}

}
