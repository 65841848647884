import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ContentCard } from 'src/app/models/content-card';

@Component({
  selector: 'app-video-card',
  templateUrl: './video-card.component.html',
  styleUrls: ['./video-card.component.scss']
})
export class VideoCardComponent {

	@Input() cardData: ContentCard;
	@Output() onPlay: EventEmitter<ContentCard> = new EventEmitter();

	constructor() { }

	ngOnInit(): void {
	}

	playVideo(): void {
		this.onPlay.emit(this.cardData);
	}
}
