import { ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { CheckboxChangeEvent } from 'primeng/checkbox';
import { DocTag } from 'src/app/models/document';
import { DataService } from 'src/app/services/data.service';
import { SiteService } from 'src/app/services/site.service';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent {

	public allTags: Array<DocTag> = [];
	public tagsToShow: Array<DocTag> = [];
	@Input() selectedTagIds: Array<string> = [];
	@Input() allowEditing: boolean = false;
	@Output() selectedTagIdsChange: EventEmitter<Array<string>> = new EventEmitter();
	@Output() onTagDeleted: EventEmitter<string> = new EventEmitter();
	@Output() onTagEdited: EventEmitter<DocTag> = new EventEmitter();
	@Output() onTagAdded: EventEmitter<DocTag> = new EventEmitter();

	public selectedTags: Array<DocTag> = [];
	public newTag: string;

	public showDeleteTagConfirmation: boolean = false;
	public deletingTagId: string;
	public showEditTagDialog: boolean = false;
	public editingTag: DocTag;
	
	constructor(private dataService: DataService, private siteService: SiteService, private changeDetectorRef: ChangeDetectorRef) { }

	ngOnInit() {
		this.dataService.getTags().subscribe((tags:any) => {
			this.allTags = tags.sort((a:any, b:any) => a.tagDesc.localeCompare(b.tagDesc));
			this.tagsToShow = this.allTags;
			this.selectedTags = this.allTags.filter(t => this.selectedTagIds.includes(t.tagID));
		});
		this.selectedTagIdsChange.subscribe();
	}
	ngOnChanges(changes: SimpleChanges) {
		if (changes.selectedTagIds) {
			this.selectedTags = this.allTags.filter(t => this.selectedTagIds.includes(t.tagID));
		}
	}

	onTagChanged(e: CheckboxChangeEvent, tag: DocTag) {
		this.selectedTagIds = this.selectedTags.map(t => t.tagID);
		this.selectedTagIdsChange.emit(this.selectedTagIds);
	}

	filterTags() {
		if (this.newTag) {
			this.tagsToShow = this.allTags.filter(t => t.tagDesc.toLowerCase().includes(this.newTag.toLowerCase()));
		} else {
			this.tagsToShow = this.allTags;
		}
	}
	addTag() {
		if(!this.newTag) return;
		if(!this.allowEditing) return;
		const newTag = { tagID: crypto.randomUUID(), tagDesc: this.newTag };
		this.dataService.saveTag(newTag).subscribe((tag:any) => {
			this.allTags.push(newTag);
			this.newTag = '';
			this.tagsToShow = this.allTags;
			this.onTagAdded.emit(newTag);
		});
	}
	editTag(tag: DocTag) {
		if(!this.allowEditing) return;
		this.editingTag = tag;
		this.showEditTagDialog = true;
	}

	closeEditTagDialog() {
		this.showEditTagDialog = false;
	}
	saveTag() {
		this.dataService.saveTag(this.editingTag).subscribe(() => {
			this.siteService.setMessage('Tag saved successfully', 'success');
			this.showEditTagDialog = false;
			this.onTagEdited.emit(this.editingTag);
			this.editingTag = null;
		});
	}

	deleteTag(tag: DocTag) {
		if(!this.allowEditing) return;
		this.showDeleteTagConfirmation = true;
		this.deletingTagId = tag.tagID;
	}

	onConfirmClick({ action, data }: {action: string, data: any}) {
		switch(action) {
			case 'Cancel':
				this.showDeleteTagConfirmation = false;
				break;
			case 'Delete Tag':
				this.dataService.deleteTag(data).subscribe(() => {
					this.siteService.setMessage('Tag deleted successfully', 'success');
					this.showDeleteTagConfirmation = false;
					this.onTagDeleted.emit(data);
					this.allTags = this.allTags.filter(t => t.tagID !== data);
					this.filterTags();
					this.selectedTagIds = this.selectedTagIds.filter(t => t !== data);
					this.selectedTagIdsChange.emit(this.selectedTagIds);
				});
				break;
			}
		}

}
