import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ApiService {
	private apiUrl: string = '/api';
	private defaultHeaders: HttpHeaders;
	private fileHeaders: HttpHeaders;
	constructor(private http: HttpClient) {
		this.defaultHeaders = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
		this.fileHeaders = new HttpHeaders().set('Content-Type', 'text/csv; charset=utf-8');
	}

	getUrl(prefix: string) {
		if (this.apiUrl != '') {
			return `${this.apiUrl}/${prefix}`;
		} else {
			return `${prefix}`;
		}
	}

	get<T>(prefix: string, headers: HttpHeaders = null): Observable<T> {
		let options = {};
		if (!!headers) {
			options = { headers: headers };
		} else {
			options = { headers: this.defaultHeaders };
		}
		return this.http.get<T>(this.getUrl(prefix), options);
	}

	getFile(prefix: string): Observable<string> {
		return this.http.get(this.getUrl(prefix), { headers: this.fileHeaders, responseType: 'text' });
	}

	getBlob(prefix: string): Observable<any> {
		return this.http.get(this.getUrl(prefix), { responseType: 'blob' });
	}
	post<T>(prefix: string, objectToPost: any, headers: HttpHeaders = null, responseType: string = null): Observable<T> {
		let options = {};
		if (!!headers) {
			options = { headers: headers };
		} else {
			options = { headers: this.defaultHeaders };
		}
		if(responseType) {
			Object.assign(options, { responseType })
		}
		return this.http.post<T>(this.getUrl(prefix), JSON.stringify(objectToPost), options);
	}

	put<T>(prefix: string, objectToPost: any): Observable<T> {
		return this.http.put<T>(this.getUrl(prefix), JSON.stringify(objectToPost), { headers: this.defaultHeaders });
	}

	delete<T>(prefix: string): Observable<T> {
		return this.http.delete<T>(this.getUrl(prefix));
	}

	postBlob(prefix: string, formData: FormData): Observable<any> {
		const headers = new HttpHeaders({ enctype: 'multipart/form-data' });
		return this.http.post(this.getUrl(prefix), formData, { headers: headers });
	}
}
