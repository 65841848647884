<section id="stressors">
	<div class="stressor-grid stage-{{stage}}">
		<div class="copy">
			<div class="headline">Life's stressors <em>don't</em> discriminate.</div>
			<div class="text">Whatever you're going through, the burden doesn't have to rest on your shoulders alone.  Your EAP is here for you, with experts who know exactly how to help.</div>
			<div class="actions"><a class="cta" [routerLink]="['/learn-more']" [interact]="{engagementTypeID: 4, resource: 'Get Support Now'}">Get Support Now</a></div>
		</div>
		<div class="item item-1 bottom left"><span>"Will I ever be able to start a family?"</span></div>
		<div class="item item-2 bottom left"><span>"Why can't I stop after one drink?"</span></div>
		<div class="item item-3 bottom right"><span>"Will they take my car?"</span></div>
		<div class="item item-4 bottom center"><span>"How much longer can I go on like this?"</span></div>
		<div class="item item-5 bottom left"><span>"How can I support us both?"</span></div>		
	</div>
	<div class="progress stage-{{stage}}">
		<div class="dot"></div>
		<div class="dot"></div>
		<div class="dot"></div>
		<div class="dot"></div>
		<div class="dot"></div>
		<div class="dot"></div>
		<div class="dot"></div>
		<div class="dot"></div>
		<div class="dot"></div>
		<div class="dot"></div>
		<div class="dot"></div>
		<div class="dot"></div>
		<div class="dot"></div>
		<div class="bar"></div>
	</div>
</section>
