import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AdminService } from '../services/admin.service';

export const isAdminGuard: CanActivateFn = (route, state) => {
	const adminService = inject(AdminService);
	const router = inject(Router);
	if(adminService.isAdmin) {
		return true;
	} else {
		router.navigate(["admin", "login" ], { queryParams: { returnUrl: state.url } });
		return false;
	}
};

export const isSuperAdminGuard: CanActivateFn = (route, state) => {
	const adminService = inject(AdminService);
	const router = inject(Router);
	if(adminService.isSuperAdmin) {
		return true;
	} else {
		router.navigate(["admin", "login" ], { queryParams: { returnUrl: state.url } });
		return false;
	}
}