
<div class="user" *ngIf="user">
	<div class="field">
		<label for="email">Email</label>
		<input pInputText type="text" id="email" [(ngModel)]="user.emailAddress" (ngModelChange)="markDirty()"/>
	</div>
	<div class="field">
		<label for="password">Password</label>
		<p-password [feedback]="false" [(ngModel)]="user.password" (ngModelChange)="markDirty()" [appendTo]="'password'" [toggleMask]="true" [ngClass]="{'ng-invalid': errors.password}"></p-password>
		<div class="error" *ngIf="errors.password">{{errors.password}}</div>
	</div>
	<div class="field">
		<label for="first-name">First Name</label>
		<input pInputText type="text" id="first-name" [(ngModel)]="user.firstName"  (ngModelChange)="markDirty()"/>
	</div>
	<div class="field">
		<label for="last-name">Last Name</label>
		<input pInputText type="text" id="last-name" [(ngModel)]="user.lastName" (ngModelChange)="markDirty()" />
	</div>
	<div class="field">
		<label for="access-level">Access Level</label>
		<p-dropdown [options]="employers" [(ngModel)]="selectedEmployer" [optionLabel]="'employerName'" [autoDisplayFirst]="false" (onChange)="markDirty()">
			<ng-template let-employer pTemplate="item">
				<div *ngIf="employer.employerID === null" class="super-admin">Super Admin</div>
				<div *ngIf="employer.employerID !== null">{{employer.employerName}}</div>
			</ng-template>
		</p-dropdown>
	</div>
</div>


<app-confirm-dialog
	[confirmHeader]="'Are you sure?'"
	[confirmBodyMsg]="'Are you sure you want to delete this user?'"
	[showDialog]="showDeleteUserConfirmation"
	[buttons]="[{ text: 'Cancel', class: 'neutral' }, { text: 'Delete', class: 'danger' }]"
	(onClick)="onConfirmClick($event)"></app-confirm-dialog>
