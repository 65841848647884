<div class="add-document">
	<div class="tabset">
		<div class="tab" (click)="setActiveTab('existing')" [ngClass]="{ active: activeTab == 'existing' }">Existing</div>
		<div class="tab" (click)="setActiveTab('new')" [ngClass]="{ active: activeTab == 'new' }">New</div>
	</div>
	<div class="tabs">
		<div class="tab-content" *ngIf="activeTab == 'existing'">
			<div class="document-container">
				<ng-container *ngFor="let doc of allDocuments">
					<div class="document-wrapper" [ngClass]="{adding: doc.adding}">
						<app-library-item [document]="doc" [buttons]="doc.adding ? ['cancel'] : ['add']" (onAddClick)="addDocument($event)" (onCancelClick)="cancelAddDocument($event)"></app-library-item>	
					</div>
				</ng-container>
			</div>
		</div>
		<div class="tab-content" *ngIf="activeTab == 'new'">
			<div class="new-document" [formGroup]="form">
				<div class="document-data">
					<div class="field">
						<label for="title">Title</label>
						<input pInputText type="text" id="title" [formControlName]="'docTitle'" />
					</div>
					<div class="field">
						<label for="author">Author</label>
						<input pInputText id="author" [formControlName]="'docAuthor'" />
					</div>
					<div class="field">
						<label for="content">File</label>
						<app-file-upload 
							[(id)]="document.docUploadID" 
							[allowedTypes]="null" 
							(fileUploaded)="onDocumentFileChange($event)"
							(fileRemoved)="onFileRemoved()"
							 [valid]="isValid('docUploadID')">
						</app-file-upload>
					</div>

					<div class="filename" *ngIf="document.docFile">
						<span class="material-symbols-outlined">description</span>
						<span>Uploaded document:</span>
						<a [href]="document.docFile" target="_blank">{{document.docTitle}} ({{document.fileType}})</a>
					</div>


				</div>
				<div class="tags">
					<app-tags [(selectedTagIds)]="document.docTags"></app-tags>
				</div>
			</div>
		</div>
	</div>
	<div class="actions">
		<div class="button success" (click)="saveNewDocuments()" *ngIf="activeTab == 'existing'">Save</div>
		<div class="button success" (click)="createNewDocument()" *ngIf="activeTab == 'new'">Save</div>
		<div class="button neutral" (click)="close()">Close</div>			
	</div>

</div>
