import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Employer } from 'src/app/models/employer';
import { HeaderButton } from 'src/app/models/header-button';
import { DataService } from 'src/app/services/data.service';
import { HelpersService } from 'src/app/services/helpers.service';
import { SiteService } from 'src/app/services/site.service';

@Component({
  selector: 'app-employers',
  templateUrl: './employers.component.html',
  styleUrls: ['./employers.component.scss']
})
export class EmployersComponent {

	public allEmployers: Array<Employer> = [];
	public employers: Array<Employer> = [];
	public searchTerm: string = '';
	public showCopyEmployerDialog: boolean = false;
	public copyingEmployerId: string;

	public newEmployerId: string;
	public newEmployerName: string;
	public newEmployerPath: string;

	constructor(private siteService: SiteService, private dataService: DataService, private router: Router, private helpersService: HelpersService) { }

	ngOnInit(): void {
		this.siteService.pageTitle = 'Employers';
		this.loadEmployers();
		this.siteService.headerButtons = [
			new HeaderButton({ text: 'Add New', class: 'success', callback: () => this.addNew() }),
		];

	}

	loadEmployers() {
		this.dataService.getEmployers().subscribe((e) => {
			this.employers = e.sort((a, b) => a.employerName.localeCompare(b.employerName)).map((e) => new Employer(e));
			this.allEmployers = this.employers;
		});
	}
	addNew() {
		this.router.navigate(['/admin', 'employer', 'new']);
	}

	search() {
		if(this.searchTerm === '') {
			this.employers = [...this.allEmployers];
			return;
		}
		this.employers = [...this.allEmployers.filter((e) => e.employerName.toLowerCase().includes(this.searchTerm.toLowerCase()))];
	}
	copyLink(employer:Employer) {
		var textField = document.createElement("input");
		textField.value = this.helpersService.getEmployerUrl(employer.path);
		document.body.appendChild(textField);
		textField.select();
		document.execCommand("copy");
		document.body.removeChild(textField);
	}

	preview(employer:Employer) {
		const previewUrl = this.helpersService.getEmployerUrl(employer.path);
		window.open(previewUrl, "_blank");
	}

	copy(employer:Employer) {
		this.copyingEmployerId = employer.employerID;
		this.newEmployerId = crypto.randomUUID();
		this.showCopyEmployerDialog = true;
	}

	closeCopyEmployerDialog() {
		this.showCopyEmployerDialog = false;
		this.newEmployerId = '';
		this.newEmployerName = '';
		this.newEmployerPath = '';
	}

	doCopy() {
		this.dataService.copyEmployer(this.copyingEmployerId, this.newEmployerId, this.newEmployerName, this.newEmployerPath).subscribe(() => {
			this.router.navigate(['/admin', 'employer', this.newEmployerId]);
			this.closeCopyEmployerDialog();
		});
	}

}
