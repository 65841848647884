<div class="tag-container">
	<h3>Tags</h3>
	<div class="tags">
		<input pInputText [(ngModel)]="newTag" (keyup)="filterTags()" (keyup.enter)="addTag()" [placeholder]="allowEditing ? 'Add a new tag' : 'Filter tags'" />
		<p *ngIf="allowEditing && newTag">Press enter to create a new tag called <b>{{newTag}}</b></p>
		<ng-container *ngFor="let tag of tagsToShow, let i = index">
			<div class="tag">
				<p-checkbox [value]="tag" [(ngModel)]="selectedTags" [label]="tag.tagDesc" (onChange)="onTagChanged($event, tag)"></p-checkbox>
				<div class="actions" *ngIf="allowEditing">
					<div class="button neutral" (click)="editTag(tag)">Edit</div>
					<div class="button danger" (click)="deleteTag(tag)">Delete</div>
				</div>
			</div>
		</ng-container>
	</div>
</div>

<app-confirm-dialog
	[confirmHeader]="'Are you sure?'"
	[confirmBodyMsg]="'Are you sure you want to delete this tag?  This will remove the tag from all documents that have it.'"
	[showDialog]="showDeleteTagConfirmation"
	[data]="deletingTagId"
	[buttons]="[{ text: 'Cancel', class: 'neutral' }, { text: 'Delete Tag', class: 'danger' }]"
	(onClick)="onConfirmClick($event)"></app-confirm-dialog>

<ng-container *ngIf="showEditTagDialog">
	<div class="mask" *ngIf="showEditTagDialog">
		<div class="dialog">
			<h2>Edit Tag</h2>
			<div class="dialog-content">
				<label>Tag Description</label>
				<input pInputText [(ngModel)]="editingTag.tagDesc" />
			</div>
			<div class="actions">
				<div class="button neutral" (click)="closeEditTagDialog()">Cancel</div>
				<div class="button success" (click)="saveTag()">Save</div>
			</div>
		</div>
	</div>
</ng-container>