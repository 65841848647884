import { Component, Input } from '@angular/core';
import { ContentCard } from 'src/app/models/content-card';

@Component({
  selector: 'app-topic-card',
  templateUrl: './topic-card.component.html',
  styleUrls: ['./topic-card.component.scss']
})
export class TopicCardComponent {
	@Input() cardData: ContentCard;


	constructor() {}

	fixLineBreaks(text: string): string {
		if(!text) return '';
		return text.replace(/(\r\n|\r|\n)/g, '<br>');
	}
}
