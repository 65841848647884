

	<div class="mask" *ngIf="showDialog">
		<div class="dialog">
			<h2>{{ confirmHeader }}</h2>
			<div class="dialog-content">
				<p class="modal-text" *ngIf="!customBodyTemp">{{ confirmBodyMsg }}</p>
				<ng-container [ngTemplateOutlet]="customBodyTemp"></ng-container>
			</div>
			<div class="actions">
				<ng-container *ngFor="let button of buttons">
					<div class="button {{button.class}}" (click)="onButtonClick(button.text)">{{ button.text }}</div>
				</ng-container>
			</div>
		</div>
	</div>