
<header>
	<div class="logo"></div>
	<span class="material-symbols-outlined hamburger mobile" (click)="menuOpen = !menuOpen">menu</span>
	<ul [ngClass]="{ open: menuOpen }">
		<li [ngClass]="{ active: activeLink == 'home'}"><a [routerLink]="['/home']" [interact]="{engagementTypeID: 4, resource: '/home/'}">Home</a></li>
		<li [ngClass]="{ active: activeLink == 'learn-more'}"><a [routerLink]="['/learn-more']" [interact]="{engagementTypeID: 4, resource: '/learn-more/'}">Learn More</a></li>
	</ul>

</header>
<router-outlet></router-outlet>
<footer *ngIf="employer">
	<div class="logo"></div>
	<div class="socials">
		<ul>
			<li><a href="" target="_blank" style="background-image:url(/assets/images/linked-in.svg)" [interact]="{engagementTypeID: 4, resource: 'Linked In'}"></a></li>
			<li><a href="" target="_blank" style="background-image:url(/assets/images/youtube.svg)" [interact]="{engagementTypeID: 4, resource: 'YouTube'}"></a></li>
			<li><a href="" target="_blank" style="background-image:url(/assets/images/facebook.svg)" [interact]="{engagementTypeID: 4, resource: 'Facebook'}"></a></li>
			<li><a href="" target="_blank" style="background-image:url(/assets/images/twitter.svg)" [interact]="{engagementTypeID: 4, resource: 'Twitter'}"></a></li>
		</ul>
	</div>
	<div class="disclaimers">
		<ol>
			<li>Lorem ipsum dolor sit amet consectetur adipisicing.</li>
			<li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet.</li>
			<li>Lorem ipsum dolor sit amet consectetur.</li>
			<li>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolor, quam?</li>
		</ol>
	</div>
	<div class="company-logo" [ngStyle]="{'background-image': 'url(' + logo + ')'}"></div>
</footer>