
<div class="admin-template">
	<nav>
		<ul>
			<li [ngClass]="{ 'active': activeLink == 'employers', expanded: employer  }" *ngIf="isSuperAdmin">
				<a class="nav-link" [routerLink]="['/admin', 'employers']"><span class="material-symbols-outlined">groups_2</span>Employers</a>
				<div *ngIf="employer" class="subnav">
					<h4 *ngIf="false">{{employer.employerName}}</h4>
					<ul>
						<li [ngClass]="{ 'subnav-active': activeSection == 'configure'}"><a [routerLink]="['/admin', 'employer', employer.employerID, 'configure']">Configure</a></li>
						<li [ngClass]="{ 'subnav-active': activeSection == 'customizations'}"><a [routerLink]="['/admin', 'employer', employer.employerID, 'customizations']">Customizations</a></li>
						<li [ngClass]="{ 'subnav-active': activeSection == 'video-cards'}"><a [routerLink]="['/admin', 'employer', employer.employerID, 'video-cards']">Video Cards</a></li>
						<li [ngClass]="{ 'subnav-active': activeSection == 'topic-cards'}"><a [routerLink]="['/admin', 'employer', employer.employerID, 'topic-cards']">Topic Cards</a></li>
						<li [ngClass]="{ 'subnav-active': activeSection == 'library'}"><a [routerLink]="['/admin', 'employer', employer.employerID, 'library']">Library</a></li>
						<li [ngClass]="{ 'subnav-active': activeSection == 'census-data'}"><a [routerLink]="['/admin', 'employer', employer.employerID, 'census-data']">Census Data</a></li>
					</ul>
				</div>
			</li>
			<li class="expanded" *ngIf="!isSuperAdmin">
				<div class="nav-link"><span class="material-symbols-outlined">groups_2</span>Employer</div>
				<div class="subnav">
					<ul>
						<li [ngClass]="{ 'subnav-active': activeSection == 'library'}"><a [routerLink]="['/admin', 'employer', superAdminEmployerID, 'library']">Library</a></li>
						<li [ngClass]="{ 'subnav-active': activeSection == 'census-data'}"><a [routerLink]="['/admin', 'employer', superAdminEmployerID, 'census-data']">Census Data</a></li>
					</ul>
				</div>
			</li>
			<li [ngClass]="{ 'active': activeLink == 'global-cards', expanded: activeLink == 'global-cards' }" *ngIf="isSuperAdmin">
				<a class="nav-link" [routerLink]="['/admin', 'global-cards']"><span class="material-symbols-outlined">cards</span>Global Cards</a>
				<div  class="subnav">
					<ul>
						<li [ngClass]="{ 'subnav-active': activeSection == 'video-cards'}"><a [routerLink]="['/admin', 'global-cards', 'video-cards']">Video Cards</a></li>
						<li [ngClass]="{ 'subnav-active': activeSection == 'topic-cards'}"><a [routerLink]="['/admin', 'global-cards', 'topic-cards']">Text Cards</a></li>
					</ul>
				</div>
			</li>
			<li [ngClass]="{ 'active': activeLink == 'library' }" *ngIf="isSuperAdmin">
				<a class="nav-link" [routerLink]="['/admin', 'library']"><span class="material-symbols-outlined">library_books</span>Library</a>
			</li>
			<li [ngClass]="{ 'active': activeLink == 'users' }" *ngIf="isSuperAdmin">
				<a class="nav-link" [routerLink]="['/admin', 'users']"><span class="material-symbols-outlined">group</span>Users</a>
			</li>
			<li [ngClass]="{ 'active': activeLink == 'reports' }" *ngIf="isSuperAdmin">
				<a class="nav-link" [routerLink]="['/admin', 'reports']"><span class="material-symbols-outlined">analytics</span>Reports</a>
			</li>
			<li (click)="logout()" class="logout nav-link"><span class="material-symbols-outlined">logout</span>Logout</li>
		</ul>
	</nav>
	<main>
		<div class="top">
			<h1 *ngIf="pageTitle">
				<a [routerLink]="[backButton.link]" *ngIf="backButton && isSuperAdmin"><span [title]="backButton.title" class="material-symbols-outlined back-arrow">arrow_back</span></a>
				{{pageTitle}}
			</h1>
			<div class="actions">
				<div *ngFor="let button of headerButtons" class="button" [ngClass]="button.class" (click)="do(button.callback)">{{button.text}}</div>
			</div>
		</div>
		<div class="content">
			<router-outlet></router-outlet>
		</div>
	</main>	
</div>
